/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
// import { COMPONENT_TYPES } from './../../../pages/projects/data-pipeline/data-pipeline-constants';
import axiosInstance from 'api'
import { getProfileInfo } from 'api/apiUtility'
import { environment } from 'environments/environment'
import { recentDataRequestType } from 'types/request'

export const getProjectsForOrg = (payload: recentDataRequestType) => {
  return axiosInstance.post(`/account/getEditableProjectForOrg`, payload)
}

export const getProfileInfoFromAPI = () => {
  return axiosInstance.get(`/user_profile/`)
}

//TODO: will remove code after successful testing of isd-331
// export const getRecentProject = () => {
//   const orgId = getProfileInfo().teamId
//   return axiosInstance.get(
//     `/project?org_id=${orgId}&edit=true&project_type=DEFAULT`,
//   )
// }

// export const getProjectBasedOnSearch = (search: any) => {
//   const orgId = getProfileInfo().teamId
//   return axiosInstance.get(
//     `/project?org_id=${orgId}&edit=true&search=${search}&project_type=DEFAULT`,
//   )
// }

// export const getOnlyRecentProjects = () => {
//   const orgId = getProfileInfo().teamId
//   return axiosInstance.get(
//     `/project?org_id=${orgId}&edit=true&project_type=DEFAULT`,
//   )
// }

export const getProjectsAndApps = (params: any) => {
  return axiosInstance.post(
    `/project/_search?pageNumber=${params?.pageNumber || 1}&pageSize=${
      params?.pageSize || 100
    }`,
    params.search,
  )
}

export const getAllEndpointNodes = (params: any) => {
  // const componentType = 'DATA_ACCESS_API'
  return axiosInstance.get(
    `/component/output_nodes?componentId=${params?.component_id}`,
  )
}

export const getTooltipData = (functionName: any) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/data_repository_metadata/6582b5cc4806582f9f6be2ef/file?file_name=docs/functions/${functionName}.json`,
  )
}

export const getAllProjects = (params: any) => {
  // const componentType = 'DATA_ACCESS_API'
  return axiosInstance.get(
    `/component?edit=true&component_type=${params.componentType}&project_id=${params.id}`,
  )
}

export const getRecentUpdatedComponents = () => {
  const orgId = getProfileInfo().teamId
  return axiosInstance.get(`/component?org_id=${orgId}&edit=true`)
}

export const getProjectDetails = (id: string) => {
  return axiosInstance.get(`/project/details?id=${id}`)
}

export const getKnowledgeAsistant = (id: string) => {
  return axiosInstance.get(
    `/component?component_type=KNOWLEDGE_RETRIEVAL&project_id=${id}`,
  )
}
// export const getQueryResponse = (query: string) => {
//   return axiosInstance.get(`/query?query=${query}`)
// }
export const getProjectRoleDetails = (id: string) => {
  return axiosInstance.get(`/user_roles/projects?projectId=${id}`)
}

export const getDataSourcesForOrg = (payload: any) => {
  const orgId = getProfileInfo().teamId
  payload.data.org_id = orgId
  return axiosInstance.post(`/account/getDataSourcesForOrg`, payload)
}
export const disableComponentNode = (payload: any) => {
  return axiosInstance.post(`/component/disable_nodes`, payload)
}

export const getProjectActivityLog = (payload: any) => {
  return axiosInstance.post(
    `/project_activity_logs/_search?pageNumber=&pageSize=`,
    payload,
  )
}
export const getProvidersList = () => {
  return axiosInstance.get(`/model_provider/?pageNumber=&pageSize=&search=`)
}

export const getComponentDetails = (id: string) => {
  return axiosInstance.get(`/component/details?id=${id}`)
}

export const changeComponentName = (data: any) => {
  return axiosInstance.post(`/component/`, data)
}

export const publishApp = (payload: any) => {
  return axiosInstance.post(
    `/component/publish?id=${payload.id}&projectId=${payload.projectId}&appId=${payload.appId}`,
  )
}

export const getJsonConversion = (param: any) => {
  return axiosInstance.get(
    `/dq_json_conversion/?project_id=${param.projectId}&id=${param.componentId}`,
  )
}

// get component template
// export const getComponentTemplate = (payload: any) => {
//   return axiosInstance.post(`/account/getComponentTemplateForOrg`, payload)
// }

export const getComponentTemplate = (payload: any) => {
  return axiosInstance.get(
    `/component_template?org_id=${payload.data.org_id}&component_type=${payload.data.componentType}`,
  )
}

// get Data Repository
export const getDataRepository = (payload: any) => {
  return axiosInstance.post(`/data_repositories/_search`, payload)
}

export const getDataRepositoryTypes = (payload: any) => {
  return axiosInstance.post(
    `/data_repository_types/_search?pageNumber=&pageSize=`,
    payload,
  )
}

export const getAllStorageLocationTypes = () => {
  return axiosInstance.get(
    `storage_location_types/?pageNumber=&pageSize=&search=`,
  )
}

export const getDataRepositoryDetailsByID = (id: string) => {
  return axiosInstance.get(`/data_repository_types/configuration?id=${id}`)
}

// get DQ metrices
export const getDQMetrices = (payload: any) => {
  // return axiosInstance.get(`/dq_metrics`)
  return axiosInstance.post(`/dq_metrics/_search`, payload)
}
export const getDQCheckValidation = (payload: any) => {
  // return axiosInstance.get(`/dq_metrics`)
  return axiosInstance.post(`/dq_checks/_search`, payload)
}

// get Input Table Data Sets
export const getInputTableData = (dataRepositoryId: string) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/data_repository_metadata/${dataRepositoryId}?db_type=SQL`,
  )
}

export const getInputTableDataByUsingDBType = (
  dataRepositoryId: string,
  dbType: string,
) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/data_repository_metadata/${dataRepositoryId}?db_type=${dbType}`,
  )
}

export const getInputTopicData = (dataRepositoryId: string) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/kafka/topics?data_repository_id=${dataRepositoryId}`,
  )
}

export const getInputFolderData = (dataRepositoryId: string) => {
  const param = {
    data_repository_id: dataRepositoryId,
  }
  return axiosInstance.post(
    `${environment.loadBalancerPython}/data_repository_metadata/folders/`,
    param,
  )
}

// get Retain Columns
export const getRetainColumnsData = (
  dataRepositoryId: string,
  selectedTableDataSets: string,
) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/v2/data_repository_metadata/${dataRepositoryId}/columns?dataset=${selectedTableDataSets}`,
  )
}

export const getComponentNodeDetails = (nodeId: string) => {
  return axiosInstance.get(`/component_node/detail?id=${nodeId}`)
}

export const getComponentOutputNodes = (nodeIdArray: any) => {
  return axiosInstance.get(`component_node/output?ids=["${nodeIdArray}"]`)
}

export const getXYSeriesData = (componentId: any) => {
  return axiosInstance.get(`component/output?componentId=${componentId}`)
}
export const getOutputData = (params: any) => {
  return axiosInstance.get(
    `component/output?componentId=${params.componentId}&componentNodeId=${params.componentNodeId}`,
  )
}

export const getComponentNodeDetailsConfiguration = (nodeTypeId: string) => {
  return axiosInstance.get(
    `/component_node_type/configuration?id=${nodeTypeId}`,
  )
}

export const getComponentNodeTemplateConfiguration = (nodeTypeId: string) => {
  return axiosInstance.get(`/component_node_template/detail?id=${nodeTypeId}`)
}

export const getComponentNodeType = (componentType: string) => {
  return axiosInstance.get(
    `/component_node_type?componentType=${componentType}`,
  )
}

// export const getAppsForComponentPublish = (org: string) => {
//   return axiosInstance.get(`/project?project_type=APP&org_id=${org}&edit=true`)
// }

export const getAllOutputTemplateTypes = (id: any) => {
  return axiosInstance.get(
    `/component_node_template/names?componentNodeTypeId=${id}`,
  )
}

export const getApiComponentNodeType = () => {
  return axiosInstance.get(`/component_node_type?componentType=DATA_ACCESS_API`)
}

export const updateComponentNode = (payload: any) => {
  return axiosInstance.post(`/component_node`, payload)
}

export const updateComponent = (payload: any) => {
  return axiosInstance.post(`/component`, payload)
}

export const addNewProject = (payload: any) => {
  return axiosInstance.post(`/project`, payload)
}
/** Get Eligible input nodes for transformation node */
export const getInputNodes = (arg: any) => {
  const { componentId, componentNodeId } = arg
  return axiosInstance.get(
    `/component/eligible_input_nodes?componentId=${componentId}&componentNodeId=${componentNodeId}`,
  )
}

/** Get Component Node Output for transformation node*/
export const getOutputNodes = (arg: any) => {
  const { inputNodeIds } = arg
  return axiosInstance.get(`/component_node/output?ids=${inputNodeIds}`)
}

/** Get All Transformation Functions */
export const getTransformationFunctions = (payload: any) => {
  return axiosInstance.post(
    `/transformation_functions/_search?pageNumber=1&pageSize=150`,
    payload,
  )
}
export const UpdateTransformationFunctions = (payload: any) => {
  return axiosInstance.post(`/transformation_functions/`, payload)
}

export const getAllOrganizations = (payload: any) => {
  return axiosInstance.post(`/organizations/_search?pageSize=100&pageNumber=1`)
}
export const getAllRolePermissions = (payload: any) => {
  return axiosInstance.post(
    `/role_permissions/_search?pageNumber=&pageSize=`,
    payload,
  )
}
export const getOrgPreference = () => {
  return axiosInstance.get(`/system_preferences`)
}
export const getAllTypes = () => {
  return axiosInstance.get(`/transformation_functions/types`)
}
export const getAllFileTypes = () => {
  return axiosInstance.get(`/transformation_functions/file_types`)
}
export const getAllSubTypes = () => {
  return axiosInstance.get(`/transformation_functions/subtypes`)
}
export const getAllDbTypes = () => {
  return axiosInstance.get(`/transformation_functions/dbtypes`)
}
export const getAllFunctionParameterTypes = () => {
  return axiosInstance.get(`/transformation_functions/parameter_types`)
}

export const getEventTypeModules = () => {
  return axiosInstance.get(`/event_types/modules`)
}
export const getEventTypePriorities = () => {
  return axiosInstance.get(`/event_types/priorities`)
}
export const getEventTypeCategories = (moduleId: string) => {
  return axiosInstance.get(`/event_types/categories?module=${moduleId}`)
}
export const getAllEventTypes = (moduleId: string) => {
  return axiosInstance.get(`/event_types/basic_info?module=${moduleId}`)
}
export const getNotificationChannel = () => {
  return axiosInstance.post(`/notification_channels/_search`)
}
export const getEventTypeSearch = (payload: any) => {
  return axiosInstance.post(`/event_types/_search`, payload)
}
export const getNotificationPreference = (payload: any) => {
  return axiosInstance.post(
    `/notification_preferences/_search?pageNumber=&pageSize=`,
    payload,
  )
}
export const updateNotificationPreference = (payload: any) => {
  return axiosInstance.post(`notification_preferences/`, payload)
}
export const getNotificationPreferenceDetails = (projectId: any) => {
  return axiosInstance.get(`/notification_preferences/?id=${projectId}`)
}
export const UpdateNotificationPreference = (payload: any) => {
  return axiosInstance.get(`/notification_preferences/`, payload)
}
export const getProjectMemberRoles = (id: any) => {
  return axiosInstance.get(`/user_roles/project_members_roles?projectId=${id}`)
}

export const updateOrgPreference = (payload: any) => {
  return axiosInstance.post(`/system_preferences/`, payload)
}

export const getOrganizationDetails = (projectId: string) => {
  return axiosInstance.get(`/organizations/?id=${projectId}`)
}

export const onBoarding = (payload: any) => {
  return axiosInstance.post(`/organizations/onboarding`, payload)
}
export const updateOrganizationStatus = (payload: any) => {
  return axiosInstance.patch(`/organizations/status`, payload)
}
export const updateOrganization = (payload: any) => {
  return axiosInstance.patch(`/organizations`, payload)
}

export const getAllModelInstances = (payload: any) => {
  return axiosInstance.post(
    `/model_instances/_search?pageNumber=1&pageSize=100`,
    payload,
  )
}
export const getAllDQCheckFunctions = (payload: any) => {
  return axiosInstance.post(
    `/dq_check_functions/_search?pageNumber=1&pageSize=100`,
    payload,
  )
}
export const getAllDQMatrixFunctions = () => {
  return axiosInstance.get(`/dq_metrics_functions?pageNumber=1&pageSize=100`)
}

export const getAllLanguageRuntimes = () => {
  return axiosInstance.get(`/language_runtimes/?pageNumber=&pageSize=&search`)
}
export const getAllDataPlatforms = () => {
  return axiosInstance.get(`/data_platforms/?pageNumber=&pageSize=&search`)
}
export const getAllDataPlatformsCustomFunctions = () => {
  return axiosInstance.get(`transformation_functions/data_platforms`)
}

/** Get All Transformation Functions Detail*/
export const getTransformationFunctionsDetail = (id: string) => {
  return axiosInstance.get(`/transformation_functions/details?id=${id}`)
}

export const getTransformationFunctionsTypes = () => {
  return axiosInstance.get(`/transformation_function_type_subtype/types`)
}

export const getTransformationFunctionsSubTypes = (type: string) => {
  return axiosInstance.get(
    `/transformation_function_type_subtype/subtypes?transformationType=${type}`,
  )
}

// export const getRecentApps = () => {
//   const orgId = 'ibm'
//   return axiosInstance.get(
//     `/project?project_type=APP&org_id=${orgId}&edit=true`,
//   )
// }

// export const getAppsBasedOnSearch = (search: any) => {
//   const orgId = 'ibm'
//   return axiosInstance.get(
//     `/project?project_type=APP&org_id=${orgId}&edit=true&search=${search}`,
//   )
// }

export const getAppReports = (projectId: string) => {
  return axiosInstance.get(
    `/component?component_type=DATA_VISUALIZATION&project_id=${projectId}`,
  )
}

export const getDetailsSnapShotData = (projectId: string) => {
  return axiosInstance.get(`/component/snapshot?id=${projectId}`)
}

export const getDetailsColumnData = (params: any) => {
  return axiosInstance.post(
    `${environment.loadBalancerPython}/data_processing/read_dataset_records`,
    params,
  )
}
export const getDetailsColumnApiData = (params: any) => {
  return axiosInstance.post(
    `${environment.loadBalancerPython}/data_processing/v2/read_dataset_records`,
    params,
  )
}

export const getFilteredColumnData = (params: {
  data_repository_id: string
  table_file_name: string
  record_limit: number
  cluster_index: any
  cluster_size: any
  custom_search: string
}) => {
  return axiosInstance.post(
    `${environment.loadBalancerPython}/data_processing/read_dataset_records`,
    params,
  )
}

export const getAppsDGraphNetworkData = (mobileNumber: string) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/dgraph/node_edges?mobile=${mobileNumber}`,
  )
}
export const getReadDgraph = (
  projectId: any,
  componentNodeId: any,
  formData: any,
) => {
  const payload = {
    component_id: projectId, // Use the projectId as the component_id
    component_node_id: componentNodeId,
    parameters: formData, // Pass the formData inside the parameters object
  }

  return axiosInstance.post(
    `${environment.CHATBOT_PYTHON}/read_dgraph`,
    payload,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
}

export const getAppsDGraphTableData = (mobileNumber: string) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/dgraph/node_edges?mobile=${mobileNumber}&denormalize=true`,
  )
}

export const getClusterSize = (param: any) => {
  return axiosInstance.get(
    `${environment.loadBalancerPython}/cluster_info/size?data_repository_id=${param.data_repository_id}&table_file_name=${param.table_file_name}`,
  )
}
export const getVerifySupport = (id: any) => {
  return axiosInstance.get(
    `/component/verify_source_support?component_id=${id}&db_type=AUDIO`,
  )
}

export const getClusterIndex = (params: any) => {
  return axiosInstance.post(
    `${environment.loadBalancerPython}/cluster_info/_index`,
    params,
  )
}

export const getDataPipelineApps = (projectId: string) => {
  return axiosInstance.get(
    `/component?component_type=DATA_PIPELINE&project_id=${projectId}`,
  )
}

export const getModelTrainingPipelineApps = (projectId: string) => {
  return axiosInstance.get(
    `/component?component_type=MODEL_TRAINING&project_id=${projectId}`,
  )
}

export const getDocumentProcessingPipelineApps = (projectId: string) => {
  return axiosInstance.get(
    `/component?component_type=DOCUMENT_PROCESSING&project_id=${projectId}`,
  )
}

export const getDQPipelineApps = (projectId: string) => {
  return axiosInstance.get(
    `/component?component_type=DQ_PIPELINE&project_id=${projectId}`,
  )
}

export const getOperationTables = (projectId: string) => {
  return axiosInstance.get(`/component/snapshot?id=${projectId}`)
}

export const getLocationOrDefendant = (arg: any) => {
  const { location, defendant } = arg
  return axiosInstance.get(
    `${environment.loadBalancerPython}/dgraph/cases/location_or_defendant?location=${location}&defendant=${defendant}`,
  )
}
