import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material'
import { Admin } from 'api/services'
import DropdownComponent from 'components/atoms/dropdown'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'

const NewProjectComponent = (props: any) => {
  const { open, setOpen, addNewProject, projectType, key } = props
  const dispatch = useDispatch<AppDispatch>()

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState<any>('')

  useEffect(() => {
    dispatch(
      Admin.getAllRegisteredUsers({
        search: '',
        is_fetch_all: true,
        status: 'LIVE',
      }),
    )
      .then((res: any) => {
        setUsers(res?.payload?.data)
      })
      .catch((error) => {
        console.log('Fetch User =', error)
      })
  }, [])

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Dialog
        key={key}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const project_name = formJson.project_name
            const project_user = formJson.project_user
            const project_description = formJson.project_description
            addNewProject({ project_name, project_user, project_description })
            handleClose()
          },
        }}
      >
        <DialogTitle>
          New {projectType === 'app' ? 'App' : 'Project'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="project_name"
            name="project_name"
            label={`${projectType === 'app' ? 'App' : 'Project'} Name`}
            fullWidth
            variant="outlined"
          />
          <Box sx={{ paddingTop: '20px' }}>
            <DropdownComponent
              sx={{ width: 361 }}
              required
              size="small"
              id="project_user"
              name="project_user"
              label="Project Admin *"
              value={selectedUser}
              onChange={(e) => {
                setSelectedUser(e.target.value)
              }}
            >
              {users?.length > 0 ? (
                users.map((item: any) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.user_id}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
          </Box>

          <Box sx={{ paddingTop: '20px' }}>
            <TextField
              required
              margin="dense"
              id="project_description"
              name="project_description"
              label={`${projectType === 'app' ? 'App' : 'Project'} Description`}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Apply</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default NewProjectComponent
