/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  TextareaAutosize,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  CONFIGURATION_INPUT_TYPES,
  DefaultTranformerPopup,
} from '../data-pipeline-constants'
import { ConfigurationInputInterface } from '../data-pipeline-types'
import DropdownComponent from 'components/atoms/dropdown'
import { useEffect, useState } from 'react'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { styled } from '@mui/material/styles'
import { ApiServices } from 'api/services'
import {
  TooltipNoDataFound,
  TooltipTemplate,
} from 'pages/projects/components/TooltipTemplate'
import { Info, RemoveRedEye } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import _ from 'lodash'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { REGEX_EXPRESSION } from 'utility/constants'

function DynamicFormForNodeDetails(
  input: ConfigurationInputInterface,
  onChangeArg: any,
  DrawerWidth: number,
  classes: any,

  singleTextSearchParams: any,
  multiTextSearchParams: any,
  multiObjectParams: any,

  showNodePropertiesDrawer?: any,
  projectRole?: any,
  drawerConfiguration?: any,
  onEditMultiNode?: any,
  onDeleteMultiNode?: any,
  handleTransformationPopup?: any,
  isInputNodeError?: any,
  handleSingleFolderClick?: any,
) {
  const DropdownMenuWidth = DrawerWidth - 100
  const objectParams_Id = input.objectParams ? input.objectParams.id : '#'
  const objectParams_Value = input.objectParams ? input.objectParams.value : '#'
  const isRequired = input.isRequired
  const {
    onTextChange,
    onDropDownChange,
    handleMultiObjDelete,
    handleDelete,
    onTextAddedForMultipleInput,
    onClickAdd,
    multiRowChangeMethods,

    onMultiColumnTextChange,
    onMultiColumnCheckboxChange,
    onDragEnd,
    setDrawerConfiguration,
    tranformationOperationData,
  } = onChangeArg

  const { singleTextSearchInputRef, singleTextSearch, setSingleTextSearch } =
    singleTextSearchParams
  const { multiTextSearchInputRef, multiTextSearch, setMultiTextSearch } =
    multiTextSearchParams

  const { MultiObjTextSearchInputRef, MultiObjTextSearch, setMultiObjSearch } =
    multiObjectParams

  const onTextChangeMultirow = multiRowChangeMethods?.onTextChange
  const onTextAddedForMultipleInputMultirow =
    multiRowChangeMethods?.onTextAddedForMultipleInput

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      overflow: 'scroll',
      height: '600px',
      maxWidth: '800px',
    },
  }))

  const handleSearchChange = (event: any) => {
    setSingleTextSearch(event.target.value)
  }

  const handleMultiTextSearchChange = (event: any) => {
    setMultiTextSearch(event.target.value)
  }

  const handleMultiSearchChange = (event: any) => {
    setMultiObjSearch(event.target.value)
  }

  const onMultiColumnSingleTextChange = (arg: any) => {
    const { input, e: event, index: currentIndex, item } = arg
    const updateDrawerConfiguration = [...drawerConfiguration]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      const values: any = { ...item, ...input.value?.[currentIndex] }
      values[event.target.name] = event.target.value
      updateDrawerConfiguration[index].value[currentIndex] = values
    }
    setDrawerConfiguration(updateDrawerConfiguration)
  }

  const onSingleColumnTextChange = (arg: any) => {
    const { input, e: event, index: currentIndex, item } = arg
    const updateDrawerConfiguration = [...drawerConfiguration]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      const values: any = { ...item, ...input.value?.[currentIndex] }
      values[event.target.name] = event.target.value
      updateDrawerConfiguration[index].value[currentIndex] = values
    }
    setDrawerConfiguration(updateDrawerConfiguration)
  }
  const onSingleColumnCheckboxChange = (arg: any) => {
    const { input, e: event, index: currentIndex, item, allData } = arg
    const aliasValueKey = input.objectInput.find(
      (item: any) => item.key === 'alias' || item.key === 'label',
    )
    const updateDrawerConfiguration = [...drawerConfiguration]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      if (updateDrawerConfiguration[index].value.length === 0) {
        const allValues = allData.map((data: any) => ({
          ...data,
          selected: false,
        }))
        updateDrawerConfiguration[index].value = allValues
      } else {
        const allValues = allData.map((data: any) => ({
          ...data,
          selected: false,
        }))
        updateDrawerConfiguration[index].value = allValues
      }
      const values: any = { ...item }
      values[event.target.name] = event.target.checked
      values.alias =
        input.value.find(
          (v: any) => v[aliasValueKey.value] === item[aliasValueKey.value],
        )?.alias ||
        input.dropdownData.find(
          (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
        )[aliasValueKey.value]

      values.label =
        input.value.find(
          (v: any) => v[aliasValueKey.value] === item[aliasValueKey.value],
        )?.label ||
        input.dropdownData.find(
          (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
        )[aliasValueKey.value]

      updateDrawerConfiguration[index].value[currentIndex] = values
    }
    setDrawerConfiguration(updateDrawerConfiguration)
  }

  const GetTooltipData = ({ functionName }: any) => {
    const [tooltipData, setTooltipData] = useState('')
    useEffect(() => {
      const fetchData = async () => {
        try {
          const resp: any = await ApiServices.getTooltipData(functionName)
          setTooltipData(TooltipTemplate(resp.data))
        } catch (error) {
          setTooltipData(TooltipNoDataFound())
        }
      }

      fetchData()
    }, [])

    return <div dangerouslySetInnerHTML={{ __html: tooltipData }} />
  }

  switch (input.type) {
    case CONFIGURATION_INPUT_TYPES.TEXTINPUT: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired ? `${input.label} *` : `${input.label}`}
            </Typography>
            <TextField
              placeholder={input.info || ''}
              fullWidth
              required={isRequired}
              variant="outlined"
              value={input.value}
              onChange={(e) => {
                onTextChange({ input, e })
              }}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
            />
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.TEXTAREA: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired ? `${input.label} *` : `${input.label}`}
            </Typography>
            <TextareaAutosize
              required={isRequired}
              aria-label="SQL"
              minRows={10}
              placeholder={input.info || 'SQL'}
              style={{ width: '100%' }}
              value={input.value}
              onChange={(e) => {
                onTextChange({ input, e })
              }}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
            />
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.MULTITEXTINPUT: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired ? `${input.label} *` : `${input.label}`}
            </Typography>
            <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={[]}
              value={input.value}
              onChange={(e, newval, reason) => {
                onTextAddedForMultipleInput({ input, value: newval })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={input.info || ''}
                />
              )}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
            />
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.MULTICOLUMN: {
      const customDropDownData = input.dropdownData
        ? [...input.dropdownData]
        : []
      if (customDropDownData.length > 0) {
        customDropDownData?.forEach((obj2: any) => {
          input.objectInput?.forEach((obj1: any) => {
            if (!Object.prototype.hasOwnProperty.call(obj2, obj1.key)) {
              obj2[obj1.key] = ''
            }
          })
        })
      }
      const findValueByKey = (key: any) => {
        const currentObj = input.objectInput?.find(
          (item: any) => item.key === key,
        )
        return currentObj
      }
      const grid = 8

      const getItemStyle = (isDragging: any, draggableStyle: any) => {
        return {
          // some basic styles to make the items look a bit nicer
          userSelect: 'none',
          // padding: grid * 2,
          // margin: `0 0 ${grid}px 0`,

          // change background colour if dragging
          // background: isDragging ? 'lightgreen' : 'grey',

          // styles we need to apply on draggables

          ...draggableStyle,
        }
      }
      const getListStyle = (isDraggingOver: any) => ({
        background: isDraggingOver ? 'lightblue' : null,
        paddingBottom: isDraggingOver ? '50px' : '0px',
        // padding: grid,
        // width: 250,
      })

      const checkAll = (arg: any) => {
        const { input } = arg
        const aliasValueKey = input.objectInput.find(
          (item: any) => item.key === 'alias' || item.key === 'label',
        )
        const configuration = tranformationOperationData || drawerConfiguration
        const updateDrawerConfiguration = [...configuration]
        const index = updateDrawerConfiguration.findIndex(
          (item) => item.key === input.key,
        )
        if (index > -1) {
          updateDrawerConfiguration[index].value = input.dropdownData.map(
            (obj: any) => {
              return {
                ...obj,
                selected: true,
                display: true,
                label:
                  input.value.find(
                    (v: any) =>
                      v[aliasValueKey.value] === obj[aliasValueKey.value],
                  )?.label ||
                  input.dropdownData.find(
                    (d: any) =>
                      d[aliasValueKey.value] === obj[aliasValueKey.value],
                  )[aliasValueKey.value],
                alias:
                  input.value.find(
                    (v: any) =>
                      v[aliasValueKey.value] === obj[aliasValueKey.value],
                  )?.alias ||
                  input.dropdownData.find(
                    (d: any) =>
                      d[aliasValueKey.value] === obj[aliasValueKey.value],
                  )[aliasValueKey.value],
              }
            },
          )
        }
        setDrawerConfiguration(updateDrawerConfiguration)
      }

      const clearAll = (arg: any) => {
        const { input } = arg
        const aliasValueKey = input.objectInput.find(
          (item: any) => item.key === 'alias' || item.key === 'label',
        )
        const configuration = tranformationOperationData || drawerConfiguration
        const updateDrawerConfiguration = [...configuration]

        const index = updateDrawerConfiguration.findIndex(
          (item) => item.key === input.key,
        )
        if (index > -1) {
          updateDrawerConfiguration[index].value = input.dropdownData.map(
            (obj: any) => {
              return {
                ...obj,
                selected: false,
                display: false,
                label:
                  input.value.find(
                    (v: any) =>
                      v[aliasValueKey.value] === obj[aliasValueKey.value],
                  )?.label || '',
                alias:
                  input.value.find(
                    (v: any) =>
                      v[aliasValueKey.value] === obj[aliasValueKey.value],
                  )?.alias || '',
              }
            },
          )
        }
        setDrawerConfiguration(updateDrawerConfiguration)
      }

      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired ? `${input.label} *` : `${input.label}`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                // gap: '27px',
                // justifyContent: 'space-between',

                justifyContent: 'center',
                gap: '5px',
                mb: '5px',
                mt: '5px',
              }}
            >
              {input.objectInput?.length > 0 &&
                input.objectInput.map((item: any) => {
                  if (item.allowDisplay) {
                    return (
                      <Box
                        key={item.key}
                        sx={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                          {item.label}
                        </Typography>
                        {item.type === 'CHECKBOX' && item.value === true && (
                          <Box sx={{ pt: '5px', display: 'flex', pl: '10px' }}>
                            <FormControl component="fieldset">
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    size="small"
                                    onChange={(e: any) => {
                                      e.target.checked
                                        ? input.dropdownData &&
                                          checkAll({ input })
                                        : input.dropdownData &&
                                          clearAll({ input })
                                    }}
                                    disabled={
                                      showNodePropertiesDrawer?.isEdit &&
                                      !projectRole?.edit_nodes
                                    }
                                  />
                                }
                                disabled={
                                  showNodePropertiesDrawer?.isEdit &&
                                  !projectRole?.edit_nodes
                                }
                                label="Select All"
                                labelPlacement="end"
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )
                  }
                })}
            </Box>

            <DragDropContext
              onDragEnd={(result) => onDragEnd({ result, input })}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={getListStyle(snapshot.isDraggingOver)}
                  >
                    {input.dropdownData?.length > 0 ? (
                      input.dropdownData.map((item: any, index: number) => {
                        return (
                          <Draggable
                            key={`${item.column_name}_${index}`}
                            draggableId={`${item.column_name}_${index}`}
                            index={index}
                          >
                            {(provided: any, snapshot: any) => (
                              <Box
                                key={`${item.column_name}_${index}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  ...getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  ),
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '5px',
                                  mb: '5px',
                                  mt: '5px',
                                }}
                              >
                                {Object.keys(item).map((childItem: any) => {
                                  const currentObjInput =
                                    findValueByKey(childItem)
                                  switch (currentObjInput?.type) {
                                    case 'TEXTINPUT':
                                      if (currentObjInput.allowDisplay) {
                                        return (
                                          <Box sx={{ flex: 1 }}>
                                            <TextField
                                              // required={isRequired}
                                              placeholder={
                                                currentObjInput.label
                                              }
                                              size="small"
                                              margin="none"
                                              sx={{ width: '120px' }}
                                              variant="outlined"
                                              defaultValue={
                                                item[currentObjInput.key]
                                              }
                                              value={
                                                input.value?.[index]?.[
                                                  currentObjInput.key
                                                ]
                                              }
                                              InputProps={{
                                                readOnly:
                                                  !currentObjInput.isEditable,
                                              }}
                                              name={currentObjInput.key}
                                              onChange={(e) => {
                                                onMultiColumnTextChange({
                                                  input,
                                                  e,
                                                  index,
                                                  item,
                                                  allData: input.dropdownData,
                                                })
                                              }}
                                              disabled={
                                                showNodePropertiesDrawer?.isEdit &&
                                                !projectRole?.edit_nodes
                                              }
                                            />
                                          </Box>
                                        )
                                      }
                                      break
                                    case 'CHECKBOX':
                                      if (currentObjInput.allowDisplay) {
                                        return (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flex: 1,
                                            }}
                                          >
                                            <Checkbox
                                              // required={isRequired}
                                              key={input.value?.[index]}
                                              checked={
                                                input.value?.[index]?.[
                                                  currentObjInput.key
                                                ]
                                              }
                                              size="small"
                                              name={currentObjInput.key}
                                              onChange={(e) => {
                                                onMultiColumnCheckboxChange({
                                                  input,
                                                  e,
                                                  index,
                                                  item,
                                                  allData: input.dropdownData,
                                                })
                                              }}
                                              disabled={
                                                showNodePropertiesDrawer?.isEdit &&
                                                !projectRole?.edit_nodes
                                              }
                                            />
                                            {/* <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Button>Up</Button>
                                    <Button>Down</Button>
                                  </Box> */}
                                          </Box>
                                        )
                                      }
                                      break
                                    case CONFIGURATION_INPUT_TYPES.SINGLETEXT:
                                      if (currentObjInput.allowDisplay) {
                                        return (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flex: 1,
                                            }}
                                          >
                                            <DropdownComponent
                                              placeholder={
                                                currentObjInput.label
                                              }
                                              value={
                                                input.value?.[index]?.[
                                                  currentObjInput.key
                                                ]
                                              }
                                              sx={{ width: '120px' }}
                                              onChange={(e) => {
                                                setSingleTextSearch('')
                                                onMultiColumnSingleTextChange({
                                                  input,
                                                  e,
                                                  index,
                                                  item,
                                                })
                                              }}
                                              name={currentObjInput.key}
                                              MenuProps={{
                                                PaperProps: {
                                                  style: {
                                                    minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                                                  },
                                                },
                                              }}
                                              disabled={
                                                showNodePropertiesDrawer?.isEdit &&
                                                !projectRole?.edit_nodes
                                              }
                                            >
                                              {!currentObjInput.dropdownData &&
                                                currentObjInput.options &&
                                                currentObjInput.options
                                                  .filter((obj: any) =>
                                                    obj.label
                                                      .toLowerCase()
                                                      .includes(
                                                        singleTextSearch.toLowerCase(),
                                                      ),
                                                  )
                                                  .map(
                                                    (
                                                      item: any,
                                                      idx: number,
                                                    ) => {
                                                      return (
                                                        <MenuItem
                                                          key={`${currentObjInput.key}_${item.id ? item.id : idx}`}
                                                          value={item.id}
                                                        >
                                                          {item.label}
                                                        </MenuItem>
                                                      )
                                                    },
                                                  )}
                                            </DropdownComponent>
                                          </Box>
                                        )
                                      }
                                      break
                                  }
                                })}
                                <DragIndicatorIcon />
                              </Box>
                            )}
                          </Draggable>
                        )
                      })
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          m: 2,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <NoRecordsFoundComponent />
                      </Box>
                    )}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.SINGLECOLUMN: {
      const customDropDownData = input.dropdownData
        ? [...input.dropdownData]
        : []
      if (customDropDownData.length > 0) {
        customDropDownData?.forEach((obj2: any) => {
          input.objectInput?.forEach((obj1: any) => {
            if (!Object.prototype.hasOwnProperty.call(obj2, obj1.key)) {
              obj2[obj1.key] = ''
            }
          })
        })
      }
      const findValueByKey = (key: any) => {
        const currentObj = input.objectInput?.find(
          (item: any) => item.key === key,
        )
        return currentObj
      }

      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired ? `${input.label} *` : `${input.label}`}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                // gap: '27px',
                // justifyContent: 'space-between',

                justifyContent: 'center',
                gap: '5px',
                mb: '5px',
                mt: '5px',
              }}
            >
              {input.objectInput?.length > 0 &&
                input.objectInput.map((item: any) => {
                  if (item.allowDisplay) {
                    return (
                      <Box
                        key={item.key}
                        sx={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                          {item.label}
                        </Typography>
                      </Box>
                    )
                  }
                })}
            </Box>

            {input.dropdownData?.length > 0 ? (
              input.dropdownData.map((item: any, index: number) => {
                return (
                  <Box
                    key={`${item}_${index}`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      mb: '5px',
                      mt: '5px',
                    }}
                  >
                    {Object.keys(item).map((childItem: any) => {
                      const currentObjInput = findValueByKey(childItem)
                      switch (currentObjInput?.type) {
                        case 'TEXTINPUT':
                          if (currentObjInput.allowDisplay) {
                            return (
                              <Box sx={{ flex: 1 }}>
                                <TextField
                                  // required={isRequired}
                                  placeholder={currentObjInput.label}
                                  size="small"
                                  margin="none"
                                  sx={{ width: '120px' }}
                                  variant="outlined"
                                  defaultValue={item[currentObjInput.key]}
                                  value={
                                    input.value?.[index]?.[currentObjInput.key]
                                  }
                                  InputProps={{
                                    readOnly: !currentObjInput.isEditable,
                                  }}
                                  name={currentObjInput.key}
                                  onChange={(e) => {
                                    onSingleColumnTextChange({
                                      input,
                                      e,
                                      index,
                                      item,
                                    })
                                  }}
                                  disabled={
                                    showNodePropertiesDrawer?.isEdit &&
                                    !projectRole?.edit_nodes
                                  }
                                />
                              </Box>
                            )
                          }
                          break
                        case 'CHECKBOX':
                          if (currentObjInput.allowDisplay) {
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                              >
                                <Checkbox
                                  // required={isRequired}
                                  key={input.value?.[index]}
                                  checked={
                                    input.value?.[index]?.[currentObjInput.key]
                                  }
                                  size="small"
                                  name={currentObjInput.key}
                                  onChange={(e) => {
                                    onSingleColumnCheckboxChange({
                                      input,
                                      e,
                                      index,
                                      item,
                                      allData: input.dropdownData,
                                    })
                                  }}
                                  disabled={
                                    showNodePropertiesDrawer?.isEdit &&
                                    !projectRole?.edit_nodes
                                  }
                                />
                              </Box>
                            )
                          }
                          break
                      }
                    })}
                  </Box>
                )
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  m: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NoRecordsFoundComponent />
              </Box>
            )}
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.SINGLETEXT: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired ? `${input.label} *` : `${input.label}`}
            </Typography>
            <DropdownComponent
              placeholder={input.info || ''}
              value={input.value}
              onChange={(e) => {
                setSingleTextSearch('')
                onDropDownChange({ input, e })
              }}
              required={isRequired}
              MenuProps={{
                PaperProps: {
                  style: {
                    minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                  },
                },
              }}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
            >
              {(!input.dropdownData || input.dropdownData.length === 0) &&
                !input.options && <MenuItem disabled>No record found</MenuItem>}
              {input.dropdownData && input.dropdownData.length > 0 && (
                <ListSubheader>
                  <TextField
                    size="small"
                    placeholder="Search..."
                    inputRef={singleTextSearchInputRef}
                    fullWidth
                    onChange={handleSearchChange}
                    value={singleTextSearch}
                    onKeyDown={(event) => event.stopPropagation()}
                    autoFocus
                  />
                </ListSubheader>
              )}

              {input.dropdownData &&
                input.dropdownData
                  .filter((obj: any) =>
                    objectParams_Id === '#'
                      ? obj
                          .toLowerCase()
                          .includes(singleTextSearch.toLowerCase())
                      : obj[objectParams_Value]
                          .toLowerCase()
                          .includes(singleTextSearch.toLowerCase()),
                  )
                  .map((item: any, idx: number) => {
                    return (
                      <MenuItem
                        key={`${input.key}_${item.id ? item.id : idx}`}
                        value={
                          objectParams_Id === '#' ? item : item[objectParams_Id]
                        }
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <ListItemText
                            primary={
                              objectParams_Id === '#'
                                ? item
                                : item[objectParams_Value]
                            }
                            style={{ flexGrow: 1 }} // Ensure it takes the remaining space
                          />
                          {input?.showTooltip && (
                            <HtmlTooltip
                              title={
                                <GetTooltipData
                                  functionName={
                                    objectParams_Id === '#'
                                      ? item
                                      : item[objectParams_Value]
                                  }
                                />
                              }
                              placement="right-start"
                              enterDelay={500}
                              leaveDelay={500}
                            >
                              <IconButton size="small">
                                <Info color="disabled" />
                              </IconButton>
                            </HtmlTooltip>
                          )}
                        </Box>
                      </MenuItem>
                    )
                  })}
              {!input.dropdownData &&
                input.options &&
                input.options
                  .filter((obj: any) =>
                    obj.label
                      .toLowerCase()
                      .includes(singleTextSearch.toLowerCase()),
                  )
                  .map((item: any, idx: number) => {
                    return (
                      <MenuItem
                        key={`${input.key}_${item.id ? item.id : idx}`}
                        value={item.id}
                      >
                        {item.label}
                      </MenuItem>
                    )
                  })}
            </DropdownComponent>
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.MULTITEXT: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
            </Box>
            <DropdownComponent
              multiple
              placeholder={input.info || ''}
              value={input.value ? input.value : []}
              onChange={(e) => onDropDownChange({ input, e })}
              required={isRequired}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  sx={{ height: 'auto !important' }}
                />
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                  },
                },
              }}
              disabled={
                !input.isEditable ||
                (showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes)
              }
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {(selected as string[]).map((value) => {
                      let selectedVal = value
                      if (objectParams_Id !== '#') {
                        const selectedObj =
                          input.dropdownData &&
                          input.dropdownData.find((item: any) => {
                            return item[objectParams_Id] == value
                          })

                        if (selectedObj) {
                          selectedVal = selectedObj[objectParams_Value]
                        }
                      }
                      return (
                        <Chip
                          key={`chip_${input.key}${value}`}
                          label={selectedVal}
                          clickable
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          className={classes.chip}
                          onDelete={(e) => handleDelete({ input, e, value })}
                        />
                      )
                    })}
                  </div>
                )
              }}
            >
              {(!input.dropdownData || input.dropdownData.length === 0) && (
                <MenuItem disabled>No record found</MenuItem>
              )}
              {input.dropdownData && input.dropdownData.length > 0 && (
                <ListSubheader>
                  <TextField
                    size="small"
                    placeholder="Search..."
                    inputRef={multiTextSearchInputRef}
                    fullWidth
                    onChange={handleMultiTextSearchChange}
                    value={multiTextSearch}
                    onKeyDown={(event) => event.stopPropagation()}
                    autoFocus
                  />
                </ListSubheader>
              )}
              {input.dropdownData && input.dropdownData.length > 1 && (
                <Box sx={{ display: 'flex', gap: '10px', pl: '27px' }}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          onChange={(e: any) => {
                            e.target.checked
                              ? input.dropdownData &&
                                onDropDownChange({
                                  input,
                                  e: {
                                    target: {
                                      value:
                                        objectParams_Id === '#'
                                          ? [...input.dropdownData]
                                          : input.dropdownData.map(
                                              (item: any) =>
                                                item[objectParams_Id],
                                            ),
                                    },
                                  },
                                })
                              : input.dropdownData &&
                                onDropDownChange({
                                  input,
                                  e: { target: { value: [] } },
                                })
                          }}
                        />
                      }
                      label="Select All"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Box>
              )}
              {input.dropdownData &&
                input.dropdownData
                  .filter((obj: any) =>
                    objectParams_Id === '#'
                      ? obj
                          .toLowerCase()
                          .includes(multiTextSearch.toLowerCase())
                      : obj[objectParams_Value]
                          .toLowerCase()
                          .includes(multiTextSearch.toLowerCase()),
                  )
                  .map((item: any, idx: number) => {
                    return (
                      <MenuItem
                        key={`${input.key}_${item.id ? item.id : idx}`}
                        value={
                          objectParams_Id === '#' ? item : item[objectParams_Id]
                        }
                      >
                        <Checkbox
                          checked={
                            objectParams_Id === '#'
                              ? input.value.indexOf(item) > -1
                              : input.value.indexOf(item[objectParams_Id]) > -1
                          }
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <ListItemText
                            primary={
                              objectParams_Id === '#'
                                ? item
                                : item[objectParams_Value]
                            }
                            style={{ flexGrow: 1 }} // Ensure it takes the remaining space
                          />
                          {input?.showTooltip && (
                            <HtmlTooltip
                              title={
                                <GetTooltipData
                                  functionName={
                                    objectParams_Id === '#'
                                      ? item
                                      : item[objectParams_Value]
                                  }
                                />
                              }
                              placement="right-start"
                              enterDelay={500}
                              leaveDelay={500}
                            >
                              <IconButton size="small">
                                <Info color="disabled" />
                              </IconButton>
                            </HtmlTooltip>
                          )}
                        </Box>
                      </MenuItem>
                    )
                  })}
            </DropdownComponent>
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.MULTIOBJECT: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
            </Box>
            <DropdownComponent
              multiple
              placeholder={input.info || ''}
              value={input.value}
              onChange={(e) => {
                onDropDownChange({ input, e })
              }}
              required={isRequired}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  sx={{ height: 'auto !important' }}
                />
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                  },
                },
              }}
              disabled={
                !input.isEditable ||
                (showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes)
              }
              renderValue={(selected: any) => {
                return (
                  <div className={classes.chips}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={`${value?.column_name} - ${value?.data_type}`}
                          clickable
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          className={classes.chip}
                          onDelete={(e) =>
                            handleMultiObjDelete({ input, e, value })
                          }
                        />
                      ))}
                    </Box>
                  </div>
                )
              }}
            >
              {(!input.dropdownData || input.dropdownData.length === 0) && (
                <MenuItem disabled>No record found</MenuItem>
              )}

              {input.dropdownData && input.dropdownData.length > 0 && (
                <ListSubheader>
                  <TextField
                    size="small"
                    placeholder="Search..."
                    inputRef={MultiObjTextSearchInputRef}
                    fullWidth
                    onChange={handleMultiSearchChange}
                    value={MultiObjTextSearch}
                    onKeyDown={(event) => event.stopPropagation()}
                    autoFocus
                  />
                </ListSubheader>
              )}

              {input.dropdownData && input.dropdownData.length > 0 && (
                <>
                  {/* Select All Checkbox */}
                  <Box sx={{ display: 'flex', gap: '10px', pl: '27px' }}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={
                              input.value.length === input.dropdownData.length
                            } // Checks if all items are selected
                            indeterminate={
                              input.value.length > 0 &&
                              input.value.length < input.dropdownData.length // Shows indeterminate state if not all selected
                            }
                            onChange={(e: any) => {
                              e.target.checked
                                ? onDropDownChange({
                                    input,
                                    e: {
                                      target: {
                                        value: [...input.dropdownData],
                                      },
                                    },
                                  })
                                : onDropDownChange({
                                    input,
                                    e: { target: { value: [] } },
                                  })
                            }}
                          />
                        }
                        label="Select All"
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Box>
                  {/* Individual Items with Checkboxes */}
                  {input.dropdownData
                    .filter(
                      (obj: any) =>
                        obj.column_name.includes(MultiObjTextSearch || '') ||
                        obj.data_type.includes(MultiObjTextSearch || ''),
                    )
                    .map((item: any) => {
                      const isSelected = input.value.some((value: any) =>
                        _.isEqual(value, item),
                      )
                      return (
                        <MenuItem key={item} value={item}>
                          <Checkbox
                            checked={isSelected}
                            onChange={(e) => {
                              const isChecked = e.target.checked
                              const newValue = isChecked
                                ? [...input.value, item]
                                : input.value.filter(
                                    (val: any) => !_.isEqual(val, item),
                                  )

                              onDropDownChange({
                                input,
                                e: { target: { value: newValue } },
                              })
                            }}
                          />
                          <ListItemText
                            primary={`${item[objectParams_Id]} - ${item[objectParams_Value]}`}
                          />
                        </MenuItem>
                      )
                    })}
                </>
              )}
            </DropdownComponent>
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.SINGLENODE:
    case CONFIGURATION_INPUT_TYPES.MULTINODE: {
      const valueLength = input.value.length
      let buttonDisabled = false
      if (input.type === CONFIGURATION_INPUT_TYPES.SINGLENODE) {
        if (input.value.length > 0) buttonDisabled = true
      }
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Accordion expanded={input.value.length > 0 ? true : false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
              >
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </AccordionSummary>
              <AccordionDetails>
                <>
                  {input.value.map(
                    (item: Record<string, string>, idx: number) => {
                      return (
                        <div
                          key={`${input.key}_${CONFIGURATION_INPUT_TYPES.MULTINODE}_${item[objectParams_Id]}`}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography gutterBottom>
                              {item[objectParams_Value]}
                            </Typography>
                            <Box sx={{ marginLeft: 'auto' }}>
                              <IconButton
                                onClick={() =>
                                  onEditMultiNode({
                                    editItem: item,
                                    objectParams_Value,
                                    objectType: input.objectType,
                                  })
                                }
                              >
                                {!projectRole?.edit_nodes ? (
                                  <RemoveRedEye />
                                ) : (
                                  <EditIcon />
                                )}
                              </IconButton>
                              <IconButton
                                disabled={!projectRole?.edit_nodes}
                                onClick={() =>
                                  onDeleteMultiNode({
                                    editItem: item,
                                    objectParams_Value,
                                    key: input.key,
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {valueLength - 1 !== idx && <Divider />}
                        </div>
                      )
                    },
                  )}
                </>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ mt: 2, mb: 3, textAlign: 'end' }}>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                onClick={() =>
                  handleTransformationPopup({
                    ...DefaultTranformerPopup,
                    open: true,
                    objectType: input.objectType,
                  })
                }
                disabled={
                  buttonDisabled ||
                  (showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes)
                }
              >
                Add {input.label}
              </Button>
              {isInputNodeError && (
                <Alert variant="filled" severity="error">
                  Please select input node
                </Alert>
              )}
            </Box>
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.MULTIROW: {
      let inputValue = input.value
      inputValue = inputValue.map((objItem: any, index: number) => {
        return objItem.map((nestedItem: any, nestedIndex: number) => {
          return {
            ...nestedItem,
            index,
            nestedIndex,
            parentKey: input.key,
          }
        })
      })
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Box sx={{ textAlign: 'end' }}>
              <IconButton
                disabled={
                  showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                }
                onClick={() => onClickAdd({ input })}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Box>
            {inputValue.map((item: any) => {
              return item.map((nestedItem: any) => {
                return DynamicFormForNodeDetails(
                  nestedItem,
                  {
                    onTextChange: onTextChangeMultirow,
                    onTextAddedForMultipleInput:
                      onTextAddedForMultipleInputMultirow,
                  },
                  DrawerWidth,
                  classes,
                  {
                    singleTextSearchInputRef,
                    singleTextSearch,
                    setSingleTextSearch,
                  },
                  {
                    multiTextSearchInputRef,
                    multiTextSearch,
                    setMultiTextSearch,
                  },
                  {
                    MultiObjTextSearchInputRef,
                    MultiObjTextSearch,
                    setMultiObjSearch,
                  },
                )
              })
            })}
          </Box>
        )
      } else {
        return null
      }
    }

    case CONFIGURATION_INPUT_TYPES.SINGLEFOLDER: {
      const getSelectedData = () => {
        if (input.value && input?.dropdownData?.length > 0) {
          const selectedItem =
            objectParams_Id === '#'
              ? input?.dropdownData?.find((data: any) => data === input.value)
              : input?.dropdownData?.find(
                  (data: any) => data[objectParams_Id] === input.value,
                )?.[objectParams_Value]
          return <Typography>{selectedItem}</Typography>
        }
      }
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
            </Box>
            <ButtonBase
              sx={{
                display: 'flex',
                minHeight: '50px',
                width: '100%',
                border: '1px solid #C4C4C4',
                borderRadius: '5px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '5px',
                flexWrap: 'wrap',
              }}
              onClick={() => handleSingleFolderClick(input)}
            >
              {getSelectedData()}
            </ButtonBase>
          </Box>
        )
      } else {
        return null
      }
    }
    case CONFIGURATION_INPUT_TYPES.SINGLEFILE: {
      const getSelectedData = () => {
        if (input.value && input?.dropdownData?.length > 0) {
          const selectedItem =
            objectParams_Id === '#'
              ? input?.dropdownData?.find((data: any) => data === input.value)
              : input?.dropdownData?.find(
                  (data: any) => data[objectParams_Id] === input.value,
                )?.[objectParams_Value]
          return <Typography>{selectedItem}</Typography>
        }
      }
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
            </Box>
            <ButtonBase
              sx={{
                display: 'flex',
                minHeight: '50px',
                width: '100%',
                border: '1px solid #C4C4C4',
                borderRadius: '5px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '5px',
                flexWrap: 'wrap',
              }}
              onClick={() => handleSingleFolderClick(input)}
            >
              {getSelectedData()}
            </ButtonBase>
          </Box>
        )
      } else {
        return null
      }
    }

    default: {
      if (input.allowDisplay !== false) {
        return (
          <Box key={input.key} sx={{ mt: 2 }}>
            <Typography variant="body2">
              {input.isRequired
                ? `${input.label} (only ${input.type}) *`
                : `${input.label} (only ${input.type})`}
            </Typography>
            <TextField
              placeholder={input.info || ''}
              fullWidth
              required={isRequired}
              variant="outlined"
              value={input.value}
              onChange={(e) => {
                const inputValue = e?.target?.value
                if (input?.type && input.type.toLowerCase() === 'integer') {
                  if (
                    inputValue === '' ||
                    REGEX_EXPRESSION.INTEGER.test(inputValue)
                  ) {
                    onTextChange({ input, e })
                  }
                } else if (
                  input?.type &&
                  input.type.toLowerCase() === 'float'
                ) {
                  if (
                    inputValue === '' ||
                    REGEX_EXPRESSION.FLOAT.test(inputValue)
                  ) {
                    onTextChange({ input, e })
                  }
                } else {
                  onTextChange({ input, e })
                }
              }}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
            />
          </Box>
        )
      } else {
        return null
      }
    }
  }
}

export default DynamicFormForNodeDetails
