/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import TableChartComponent from 'components/components/TableChartComponent'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import AppNetworkVisualization from 'pages/apps/app-network-visualization'
import AppKnowledgeGraphVisualization from 'pages/apps/knowledge-graph'
import TwoDChart from 'pages/apps/two-d-chart'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'
import { setComponentName } from 'store/breadcrumbSlice'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import TwoDTableApiBased from 'components/components/TwoDTableApiBased'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'

const AppGraphDashboard = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [componentId, setComponentId] = useState('')
  const [projectId, setProjectId] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [chartComponentData, setChartComponentData] = useState<any>()
  const [componentType, setComponentType] = useState('')
  useEffect(() => {
    if (searchParams) {
      const component_Id = searchParams.get('componentId')
      component_Id && setComponentId(component_Id)
      const project_Id = searchParams.get('projectId')
      project_Id && setProjectId(project_Id)
    }
  }, [searchParams])

  const callRecentAppsDataApi = useCallback(async (projectId: string) => {
    try {
      setLoading(true)
      const resp = await ApiServices.getDetailsSnapShotData(projectId)
      dispatch(setComponentName(resp?.data?.component_name))
      setLoading(false)
      setChartComponentData(resp.data)
      if (
        resp.data.configuration.snapshot[0].configuration.snapshot.node_template
      ) {
        setComponentType(
          resp.data.configuration.snapshot[0].configuration.snapshot
            .node_template,
        )
      } else if (resp.data.configuration.snapshot[0].component_node_template) {
        setComponentType(
          resp.data.configuration.snapshot[0].component_node_template,
        )
      }
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    if (componentId) {
      callRecentAppsDataApi(componentId)
    }
  }, [callRecentAppsDataApi, componentId])

  // console.log('Component Type =', componentType)

  return (
    <>
      {(componentType === 'VISUALIZATION_01_SUBCOMPONENT_TYPE_NETWORK' ||
        componentType === 'VISUALIZATION_TEMPLATE_NETWORK_GRAPH_API_BASED') && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <AppNetworkVisualization
            setLoading={setLoading}
            projectId={componentId}
          />
        </Box>
      )}
      {(componentId === '66066b917a751698259a7d0c' ||
        componentId === '66c8394bf2dabb1b9ed6c296') && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <AppKnowledgeGraphVisualization
            setLoading={setLoading}
            projectId={componentId}
          />
        </Box>
      )}

      {(componentType === 'VISUALIZATION_TEMPLATE_2DTABLE_API_BASED' ||
        componentType ===
          'VISUALIZATION_TEMPLATE_2DTABLE_VECTOR_API_BASED') && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          {chartComponentData?.configuration?.snapshot?.length > 0 &&
            chartComponentData?.configuration?.snapshot?.map(
              (snap: any, index: number) => {
                const filterGraphUrl = `${window.location.origin}/${PAGE_ROUTE_CONSTANTS.APPS_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_FILTERED_GRAPH_DASHBOARD}?projectId=${projectId}&componentId=${componentId}&index=${index}`
                return (
                  <Accordion
                    key={index}
                    defaultExpanded={index === 0 ? true : false}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'left',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="button"
                          gutterBottom
                          sx={{ fontWeight: 'bold', fontSize: 18 }}
                        >
                          {snap.component_node_name || `Accordion ${index}`}
                        </Typography>
                        <OpenInFullIcon
                          sx={{
                            marginLeft: 'auto',
                            marginRight: '20px',
                            fontSize: '20px',
                            color: 'gray',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(
                              filterGraphUrl,
                              '_blank',
                              'noopener,noreferrer',
                            )
                          }}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TwoDTableApiBased
                        chartData={chartComponentData}
                        setLoading={setLoading}
                        index={index}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              },
            )}
        </Box>
      )}

      {componentType === 'VISUALIZATION_01_SUBCOMPONENT_TYPE_TABLE' && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <TableChartComponent
            chartData={chartComponentData}
            setLoading={setLoading}
          />
        </Box>
      )}
      {(componentType === 'VISUALIZATION_TEMPLATE_2DCHART_DATA_REPO_BASED' ||
        componentType ===
          'VISUALIZATION_TEMPLATE_BARCHART_DATA_REPO_BASED') && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <TwoDChart chartData={chartComponentData} setLoading={setLoading} />
        </Box>
      )}
      <LoaderComponent open={isLoading} />
    </>
  )
}
export default AppGraphDashboard
