/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { ApiServices } from 'api/services'
import AppNetworkFilter from './app-filter'
import { INFINITY_X_BREADCRUMBS } from 'utility/constants'
import { COLORS_ARRAY } from 'pages/apps/apps-utility/utility'
import ChartTable from 'components/components/ChartTable'
import GraphComponent from './graph-component'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'
import DrawerComponent from 'components/atoms/drawer'
import { getReadDgraph } from 'api/services/api-services'

const label = { inputProps: { 'aria-label': 'Switch demo' } }

const AppNetworkVisualization = (props: any) => {
  const { setLoading, projectId } = props
  const [networkData, setNetworkData] = useState({
    nodes: [],
    edges: [],
  })
  const [chartComponentData, setChartComponentData] = useState<any>()
  const [searchedMobileNumber, setSearchedMobileNumber] = useState('')
  const [showNoData, setShowNoData] = useState(false)
  const [tableData, setTableData] = useState<any>([])
  const [edgesTypesColors, setEdgesTypesColors] = useState<any>([])
  const [chartTableToggle, setChartTableToggle] = useState(false)
  const navigate = useNavigate()
  const [formData, setFormData] = useState<Record<string, string>>({})

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    getAppsDGraphNetworkData(formData)
    // Submit logic here
  }
  useEffect(() => {
    const ele = document.getElementById(INFINITY_X_BREADCRUMBS)
    if (ele) {
      ele.style.marginLeft = '300px'
    }
    return () => {
      if (ele) {
        ele.style.marginLeft = '0px'
      }
    }
  }, [])

  const getAppsDGraphNetworkData = async (formData: any) => {
    try {
      setShowNoData(false);
      setLoading(true);
  
      // Fetch data from the API
      const resp = await ApiServices.getReadDgraph(
        chartComponentData?.configuration?.snapshot?.[0]?.configuration?.snapshot?.data_acess_api,
        chartComponentData?.configuration?.snapshot?.[0]?.configuration?.snapshot?.endpoint_node,
        formData
      );
  
      const edgeTypes = resp.data.data.edgeTypes;
      const edgeColorData: any[] = edgeTypes.map((item: string, index: number) => ({
        color: COLORS_ARRAY[index],
        name: item,
      }));
  
      setEdgesTypesColors(edgeColorData);
  
      const edges = resp.data.data.edges;
      const edgesWithColor = edges.map((item: any) => ({
        data: {
          ...item.data,
          color: edgeColorData.find((colorItem) => colorItem.name === item.data.type)?.color,
        },
      }));
  
      setNetworkData({
        nodes: resp.data.data.nodes,
        edges: edgesWithColor,
      });
  
      // Handle the case where no nodes are returned
      if (resp.data.data.nodes.length === 0) {
        setShowNoData(true);
      }
    } catch (error: any) {
      console.error('Error fetching DGraph network data:', error);
      setShowNoData(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  

  const getAppsDGraphTableData = useCallback(
    async (mobileNumber: string) => {
      try {
        setShowNoData(false)
        setSearchedMobileNumber(mobileNumber)
        setLoading(true)
        const resp = await ApiServices.getAppsDGraphTableData(mobileNumber)
        setTableData(resp.data.data)
      } catch (error: any) {
        if (chartTableToggle) {
          setShowNoData(true)
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, chartTableToggle],
  )

  const tableComponent = useCallback(() => {
    const tableKeys =
      tableData && tableData.length > 0 ? Object.keys(tableData[0]) : []
    const tableColumns: any = []
    tableKeys.forEach((item: string) => {
      if (item == 'source' || item == 'target') {
        const sourceTargetItem = Object.keys(tableData[0][item])

        sourceTargetItem.forEach((item2) => {
          const obj: any = {
            field: `${item}.${item2}`,
            headerName: `${item}.${item2}`.toUpperCase(),
            width: 150,
            valueGetter: (value: any, row: any) => {
              return `${row[item][item2]}`
            },
          }
          tableColumns.push(obj)
        })
      } else {
        const obj = {
          field: item,
          headerName: item.toUpperCase(),
          width: 150,
        }
        tableColumns.push(obj)
      }
    })
    return (
      <div style={{ width: '100%', maxWidth: '75vw' }}>
        <ChartTable columns={tableColumns} rows={tableData} />
      </div>
    )
  }, [tableData])

  const callRecentAppsDataApi = useCallback(async (projectId: string) => {
    try {
      setLoading(true)
      const resp = await ApiServices.getDetailsSnapShotData(projectId)
      setLoading(false)
      setChartComponentData(resp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    if (projectId) {
      callRecentAppsDataApi(projectId)
    }
  }, [])
  // const onSubmit = useCallback(
  //   (event: any) => {
  //     event.preventDefault()
  //     getAppsDGraphNetworkData('9503864627')
  //   },
  //   [getAppsDGraphNetworkData],
  // )
  useEffect(() => {
    // Check if filter_columns exist before processing
    const columns =
      chartComponentData?.configuration?.snapshot[0]?.configuration?.snapshot
        ?.filter_columns

    if (columns) {
      const initialFormData = columns.reduce(
        (acc: Record<string, string>, column: any) => {
          const key = column.alias || column.column_name
          // Set the default value from column.default, if available
          acc[key] = column.default || '' // Default to empty string if no default
          return acc
        },
        {},
      )

      // Set the initial formData
      setFormData(initialFormData)
    }
  }, [chartComponentData])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {/* <AppNetworkFilter
          getAppsDGraphNetworkData={getAppNetworkAndTableData}
        /> */}
        <DrawerComponent anchor={'left'} open={true} width={'300px'} >
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Configure Graph
          </Typography>
          <Container sx={{ mt: 5, p: '0 !important' }}>
            <form onSubmit={onSubmit}>
              {chartComponentData?.configuration?.snapshot[0]?.configuration?.snapshot?.filter_columns?.map(
                (column: any, index: any) => (
                  <TextField
                    key={index}
                    fullWidth
                    variant="outlined"
                    label={column.label}
                    name={column.alias || column.column_name}
                    type={column.data_type === 'string' ? 'text' : 'number'}
                    value={
                      formData[column.alias || column.column_name] ||
                      column.default ||
                      ''
                    }
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                  />
                ),
              )}

              <Box
                sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}
              >
                {/* <Button variant="contained">Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  // disabled={mobileNumber ? false : true}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Container>
        </DrawerComponent>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <Typography>Chart</Typography>
              <Switch
                {...label}
                checked={chartTableToggle}
                onChange={(e: any) => setChartTableToggle(e.target.checked)}
              />
              <Typography>Table</Typography>
            </Box>
            {chartTableToggle ? (
              tableComponent()
            ) : (
              <GraphComponent
                networkData={networkData}
                searchedMobileNumber={searchedMobileNumber}
                showNoData={showNoData}
                // getAppNetworkAndTableData={getAppNetworkAndTableData}
                edgesTypesColors={edgesTypesColors}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AppNetworkVisualization
