import React, { useCallback } from 'react'
// import _ from 'lodash'
import {
  Select,
  MenuItem,
  //   Checkbox,
  ListItemText,
  FormControl,
  OutlinedInput,
  InputLabel,
} from '@mui/material'

interface MultiObjDropdownProps {
  value: any
  onChange: any
  options: any
  label?: string
  width?: number
  isRequired?: boolean
  name?: string
  disabled?: boolean
  state?: any
}

const SingleTextDropdown = ({
  label = 'Select Options',
  value,
  onChange,
  width = 361,
  isRequired = false,
  name,
  disabled,
  options,
}: MultiObjDropdownProps) => {
  const handleChange = useCallback((event: any) => {
    const selectedKeys = event.target.value as string[]

    onChange(selectedKeys, name)
  }, [])

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel id="multi-select-label">
        {isRequired ? `${label} *` : `${label}`}
      </InputLabel>
      <Select
        labelId="multi-select-label"
        size="small"
        sx={{ width: width }}
        name={name}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={isRequired ? `${label} *` : `${label}`} />}
        disabled={disabled}
      >
        {options?.map((option: any) => (
          <MenuItem
            key={option.id || option.key}
            value={option.id || option.key}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SingleTextDropdown
