/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import React, { useCallback, useEffect, useState } from 'react'
import { ApiServices } from 'api/services'
import {
  CONFIGURATION_INPUT_TYPES,
  DocumentProcessingConfigurationId,
  MODEL_INSTANCE_ID,
  ModelDataPreparationConfigurationId,
  NODE_TYPES_NAMES,
  ResponseBuilderChildNodeTransformationId,
  TransformationConfigurationIdDataFrame,
  edge_transformationsId,
  knowledgeRetrievalSourceTransformationId,
} from '../../data-pipeline-constants'
import _without from 'lodash/without'
import _ from 'lodash'
import { getRandomNumber } from 'utility/utility'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TRANSFORMATION_FN_CONSTANTS } from 'utility/constants'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { ConfigurationInputInterface } from '../../data-pipeline-types'
import DynamicFormForNodeDetails from '../../components/DynamicFormForNodeDetails'

const DocProcessingDataDialog = (props: any) => {
  const {
    openTransformerPopup,
    handleTransformationDialogClose: handleClose,
    tranformationOperationConfiguration,
    inputConfigurationData,
    componentId,
    setLoading,
    updateTransformationApply,
    NODE_TYPES,
    outputNodeData,
    selectedTranformationTemplateId,
    componentNodeTypeId,
    projectRole,
    projectId,
    originNodeDetail,
    drawerConfiguration,
    inputTable,
    dynamicFormForNodeParams,
  } = props

  const [searchParams] = useSearchParams()

  const [nodeName, setNodeName] = useState('')
  const [dialogTitle, setDialogTitle] = useState('')
  const [paramsOfTransformationFunction, setParamsOfTransformationFunction] =
    useState<any>([])
  const [tranformationOperationData, setTranformationOperationData] = useState<
    ConfigurationInputInterface[]
  >([])

  const [componentType, setComponentType] = useState('')
  const [inputTableData, setInputTableData] = useState<any>([])

  const { DOCUMENT_TYPE, TRANSFORMATION_TYPE } = TRANSFORMATION_FN_CONSTANTS
  const {
    DrawerWidth,
    classes,
    singleTextSearchInputRef,
    singleTextSearch,
    setSingleTextSearch,
    multiTextSearchInputRef,
    multiTextSearch,
    setMultiTextSearch,
    MultiObjTextSearchInputRef,
    MultiObjTextSearch,
    setMultiObjSearch,
    showNodePropertiesDrawer,
    onEditMultiNode,
    onDeleteMultiNode,
    handleTransformationPopup,
    isInputNodeError,
    handleSingleFolderClick,
  } = dynamicFormForNodeParams

  const navigate = useNavigate()
  useEffect(() => {
    const compType = searchParams.get('componentType')
    compType && setComponentType(compType)
  }, [searchParams, setComponentType])

  const transformationFunctions = useCallback(
    async (arg: any) => {
      const { key } = arg
      let dbType = ''
      const params: any = {}

      if (
        selectedTranformationTemplateId ===
        NODE_TYPES_NAMES.DOCUMENT_031_STAGING.id
      ) {
        params.transformation_type = TRANSFORMATION_TYPE.EMBEDDING
      }
      if (componentType === 'DOCUMENT_PROCESSING') {
        dbType = DOCUMENT_TYPE.DOCUMENT
      }
      if (componentType === 'DATA_PIPELINE') {
        dbType = TRANSFORMATION_TYPE.SQL
      }
      if (
        selectedTranformationTemplateId ===
        NODE_TYPES_NAMES.RETRIEVAL_031_RAG.id
      ) {
        dbType = DOCUMENT_TYPE.DOCUMENT
        params.transformation_type = TRANSFORMATION_TYPE.RETRIEVAL
      }
      if (
        selectedTranformationTemplateId ===
          NODE_TYPES_NAMES.DOCUMENT_011_SOURCE.id ||
        selectedTranformationTemplateId ===
          NODE_TYPES_NAMES.TRAINING_011_SOURCE.id
      ) {
        dbType = DOCUMENT_TYPE.DOCUMENT
        params.transformation_type = TRANSFORMATION_TYPE.DS
      }
      if (
        selectedTranformationTemplateId ===
          knowledgeRetrievalSourceTransformationId ||
        selectedTranformationTemplateId ===
          ResponseBuilderChildNodeTransformationId
      ) {
        dbType = DOCUMENT_TYPE.DOCUMENT
        params.transformation_type = TRANSFORMATION_TYPE.SOURCE_TRANSLATION
      }
      if (
        selectedTranformationTemplateId ===
          TransformationConfigurationIdDataFrame ||
        selectedTranformationTemplateId === ModelDataPreparationConfigurationId
      ) {
        dbType = TRANSFORMATION_TYPE.SQL
      }
      if (
        selectedTranformationTemplateId ===
        NODE_TYPES_NAMES.TRAINING_021_EXTRACTION.id
      ) {
        // dbType = DOCUMENT_TYPE.DOCUMENT
        // params.transformation_type = TRANSFORMATION_TYPE.DOCUMENT_EXTRACTION
      }
      if (
        selectedTranformationTemplateId ===
        NODE_TYPES_NAMES.TRAINING_041_TARGET.id
      ) {
        params.transformation_type = TRANSFORMATION_TYPE.MODEL_TRAINING
      }
      try {
        setLoading(true)
        const resp: any = await ApiServices.getTransformationFunctions({
          // is_higer_order_function: check,
          db_type: dbType ? [dbType] : [],
          visibilities: ['ORG', 'PUBLIC'],
          ...params,
        })
        setLoading(false)
        const sourceList = resp.data.data
        setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )

          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentType],
  )

  const getOutputNodes = useCallback(async (arg: Record<string, any>) => {
    const { key: inputDataFrameKey, inputNodeIds, key2: retainColumnsKey } = arg
    try {
      const payload = {
        inputNodeIds: JSON.stringify(inputNodeIds),
      }
      const resp: any = await ApiServices.getOutputNodes(payload)
      const sourceList = resp.data
      setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository_inputDataFrame = newData.find(
          (item: ConfigurationInputInterface) => item.key === inputDataFrameKey,
        )
        if (repository_inputDataFrame) {
          repository_inputDataFrame.dropdownData =
            sourceList.output_dataframe || sourceList.retain_variables
        }
        if (retainColumnsKey) {
          const repository_retainColumns = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === retainColumnsKey,
          )

          if (repository_retainColumns) {
            repository_retainColumns.dropdownData = sourceList.retain_columns
          }
        }

        return [...newData]
      })
    } catch (error: any) {
      if (
        error?.response?.data?.error ===
          'User session not authorized. Please sign in' ||
        error?.response?.data?.error === 'jwt expired'
      ) {
        removeToken()
        removeProfileInfo()
        navigate('/login')
      }
      console.log('error', error)
    }
  }, [])

  const transformationFunctionsDetail = useCallback(
    async (arg: any) => {
      const { id } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getTransformationFunctionsDetail(id)
        setLoading(false)
        setParamsOfTransformationFunction(resp.data.params)
        const isLlm = resp.data.params.filter(
          (item: any) => item.key === 'llm_model',
        )
        if (isLlm.length > 0) {
          llmModelsParams({ key: NODE_TYPES.documentProcessing.keys.llm_model })
        }
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setParamsOfTransformationFunction([])
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getRetainColumnsData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns

        setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputTableData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, key } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getInputTableData(dataRepositoryId)
        setLoading(false)
        const sourceList = resp.data.dataset
        setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const llmModels = useCallback(
    async (arg: any) => {
      const { key, instanceId = null } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getAllModelInstances({
          model_instance_status: ['LIVE'],
          search: '',
          model_instance_type_id: instanceId,
        })
        setLoading(false)
        const sourceList = resp.data.data
        setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )

          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentType],
  )

  const llmModelsParams = useCallback(
    async (arg: any) => {
      const { key, instanceId = null } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getAllModelInstances({
          model_instance_status: ['LIVE'],
          search: '',
          model_instance_type_id: instanceId,
        })
        setLoading(false)
        const sourceList = resp.data.data
        setParamsOfTransformationFunction(
          (prev: ConfigurationInputInterface[]) => {
            const newData = [...prev]
            const repository = newData.find(
              (item: ConfigurationInputInterface) => item.key === key,
            )

            if (repository) {
              repository.dropdownData = sourceList
            }
            return [...newData]
          },
        )
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentType],
  )

  const getComponentNode = useCallback(
    async (arg: any) => {
      const { id } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getComponentNodeDetails(id)
        setLoading(false)
        const inputData = resp.data.configuration.input
        inputData &&
          inputData.length > 0 &&
          setTranformationOperationData(
            (prev: ConfigurationInputInterface[]) => {
              const newData = [...prev]
              const findItemForTranformation = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key ===
                  NODE_TYPES.transformation.keys.transform_functions,
              )
              if (findItemForTranformation) {
                const findItemForTranformationInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key ===
                    NODE_TYPES.transformation.keys.transform_functions,
                )
                if (findItemForTranformationInPrevData) {
                  findItemForTranformationInPrevData.value =
                    findItemForTranformation.value

                  if (
                    findItemForTranformationInPrevData.type ===
                    CONFIGURATION_INPUT_TYPES.SINGLETEXT
                  ) {
                    findItemForTranformationInPrevData.params =
                      findItemForTranformation.params
                    setParamsOfTransformationFunction(
                      findItemForTranformation.params,
                    )
                  }
                }
              }
              const findItemForInputColumn = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key === NODE_TYPES.transformation.keys.input_columns,
              )
              if (findItemForInputColumn) {
                const findItemForInputColumnInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key === NODE_TYPES.transformation.keys.input_columns,
                )
                if (findItemForInputColumnInPrevData) {
                  findItemForInputColumnInPrevData.value =
                    findItemForInputColumn.value
                }
              }
              const findItemForOutputColumn = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key === NODE_TYPES.transformation.keys.output_column,
              )
              if (findItemForOutputColumn) {
                const findItemForOutputColumnInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key === NODE_TYPES.transformation.keys.output_column,
                )
                if (findItemForOutputColumnInPrevData) {
                  findItemForOutputColumnInPrevData.value =
                    findItemForOutputColumn.value
                }
              }

              const findItemForLlmModel = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key === NODE_TYPES.documentProcessing.keys.llm_model,
              )
              if (findItemForLlmModel) {
                const findItemForLlmModelInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key === NODE_TYPES.documentProcessing.keys.llm_model,
                )
                if (findItemForLlmModelInPrevData) {
                  findItemForLlmModelInPrevData.value =
                    findItemForLlmModel.value
                }
              }

              const findItemForInputVariable = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key ===
                  NODE_TYPES.documentProcessing.keys.input_variables,
              )
              if (findItemForInputVariable) {
                const findItemForOutputVariableInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key ===
                    NODE_TYPES.documentProcessing.keys.input_variables,
                )
                if (findItemForOutputVariableInPrevData) {
                  findItemForOutputVariableInPrevData.value =
                    findItemForInputVariable.value
                }
              }

              const findItemForOutputVariable = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key ===
                  NODE_TYPES.documentProcessing.keys.output_variable,
              )
              if (findItemForOutputVariable) {
                const findItemForOutputVariableInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key ===
                    NODE_TYPES.documentProcessing.keys.output_variable,
                )
                if (findItemForOutputVariableInPrevData) {
                  findItemForOutputVariableInPrevData.value =
                    findItemForOutputVariable.value
                }
              }

              const findItemForOutputVector = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key === NODE_TYPES.documentProcessing.keys.output_vector,
              )
              if (findItemForOutputVector) {
                const findItemForOutputVectorInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key ===
                    NODE_TYPES.documentProcessing.keys.output_vector,
                )
                if (findItemForOutputVectorInPrevData) {
                  findItemForOutputVectorInPrevData.value =
                    findItemForOutputVector.value
                }
              }

              return [...newData]
            },
          )
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [NODE_TYPES, setLoading],
  )
  const updateDropdownValuesForinputVariableColumn = useCallback(() => {
    if (outputNodeData && outputNodeData?.output_variable?.length > 0) {
      setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]

        const repository = newData.find(
          (item: ConfigurationInputInterface) =>
            item.key === NODE_TYPES.documentProcessing.keys.input_variables,
        )

        if (repository) {
          repository.dropdownData = outputNodeData?.output_variable
        }

        return [...newData]
      })
    }
    if (outputNodeData && outputNodeData?.retain_variables?.length > 0) {
      setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]

        const repository = newData.find(
          (item: ConfigurationInputInterface) =>
            item.key === NODE_TYPES.documentProcessing.keys.input_variables,
        )

        if (repository) {
          repository.dropdownData = outputNodeData?.retain_variables
        }

        return [...newData]
      })
    }

    if (outputNodeData && outputNodeData?.retain_columns?.length > 0) {
      setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]

        const repository = newData.find(
          (item: ConfigurationInputInterface) =>
            item.key === NODE_TYPES.documentProcessing.keys.input_columns,
        )

        if (repository) {
          repository.dropdownData = outputNodeData?.retain_columns
        }

        return [...newData]
      })
    }
  }, [NODE_TYPES, outputNodeData])

  useEffect(() => {
    if (
      openTransformerPopup.open &&
      tranformationOperationConfiguration?.configuration?.input?.length > 0
    ) {
      const newTranformationOperationConfiguration = _.cloneDeep(
        tranformationOperationConfiguration.configuration.input,
      )

      if (originNodeDetail.length > 0) {
        getOutputNodes({
          inputNodeIds: originNodeDetail,
          key: NODE_TYPES.transformation.keys.input_data_frame,
          key2: NODE_TYPES.transformation.keys.retain_columns,
        })
      }

      setTranformationOperationData([...newTranformationOperationConfiguration])
      transformationFunctions({
        key: NODE_TYPES.transformation.keys.transform_functions,
      })
      llmModels({ key: NODE_TYPES.documentProcessing.keys.llm_model })
      updateDropdownValuesForinputVariableColumn()
      if (openTransformerPopup.isEdit) {
        setNodeName(openTransformerPopup.nodeName)
        getComponentNode({
          id: openTransformerPopup.id,
          key: NODE_TYPES.transformation.keys.transform_functions,
        })
      }
    }
    setDialogTitle(
      tranformationOperationConfiguration?.component_node_type_name,
    )
  }, [
    openTransformerPopup,
    tranformationOperationConfiguration,

    // transformationFunctions,
    // updateDropdownValuesForinputVariableColumn,
    // getComponentNode,
    // NODE_TYPES,
    // inputTable,
  ])

  const onDropDownChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )

      if (index > -1) {
        updateDrawerConfiguration[index].value = event.target.value
      }
      setTranformationOperationData(updateDrawerConfiguration)
      if (
        input.key === NODE_TYPES.transformation.keys.transform_functions &&
        input.type === CONFIGURATION_INPUT_TYPES.SINGLETEXT
      ) {
        transformationFunctionsDetail({ id: event.target.value })
      } else if (
        input.key === NODE_TYPES.knowledgeRetrieval.keys.source_repository
      ) {
        getInputTableData({
          dataRepositoryId: event.target.value,
          key: NODE_TYPES.source.keys.input_table,
        })
      } else if (input.key === NODE_TYPES.source.keys.input_table) {
        const source_repository = drawerConfiguration.find(
          (item: any) => item.key === NODE_TYPES.source.keys.source_repository,
        )?.value
        if (selectedTranformationTemplateId === edge_transformationsId) {
          const dataSet = inputTableData?.find(
            (item: any) => item?.column_name === event.target.value,
          )
          if (source_repository) {
            getRetainColumnsData({
              dataRepositoryId: source_repository,
              dataSets: dataSet?.node_type,
              key: NODE_TYPES.transformation.keys.edge_retain_columns,
            })
            getRetainColumnsData({
              dataRepositoryId: source_repository,
              dataSets: dataSet?.node_type,
              key: NODE_TYPES.transformation.keys.edge_filter_columns,
            })
          }
        } else {
          if (source_repository) {
            getRetainColumnsData({
              dataRepositoryId: source_repository,
              dataSets: event.target.value,
              key: NODE_TYPES.transformation.keys.edge_retain_columns,
            })
            getRetainColumnsData({
              dataRepositoryId: source_repository,
              dataSets: event.target.value,
              key: NODE_TYPES.transformation.keys.edge_filter_columns,
            })
          }
        }
      }
    },
    [tranformationOperationData, NODE_TYPES, transformationFunctionsDetail],
  )

  const onDropDownChangeForParams = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...paramsOfTransformationFunction]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )

      if (index > -1) {
        updateDrawerConfiguration[index].value = event.target.value
      }
      setParamsOfTransformationFunction(updateDrawerConfiguration)
    },
    [paramsOfTransformationFunction],
  )

  const onTextChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (
          (input && input.key === 'output_dataframe') ||
          input.key === 'output_column'
        ) {
          const value = event.target.value
          const noSpacesValue = value.replace(/\s+/g, '')
          updateDrawerConfiguration[index].value = noSpacesValue
        } else {
          updateDrawerConfiguration[index].value = event.target.value
        }
      }
      setTranformationOperationData(updateDrawerConfiguration)
    },
    [tranformationOperationData],
  )

  const onMultiColumnTextChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.value
        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setTranformationOperationData(updateDrawerConfiguration)
    },
    [tranformationOperationData],
  )

  const onMultiColumnCheckboxChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg

      const aliasValueKey = input.objectInput.find(
        (item: any) => item.key === 'alias' || item.key === 'label',
      )
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.checked
        if (values.alias) {
          values.alias =
            input.value
              .filter((item: any) => item !== null)
              .find(
                (v: any) =>
                  v[aliasValueKey.value] === item[aliasValueKey.value],
              )?.alias ||
            input.dropdownData.find(
              (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
            )[aliasValueKey.value]
        } else {
          values.label =
            input.value
              .filter((item: any) => item !== null)
              .find(
                (v: any) =>
                  v[aliasValueKey.value] === item[aliasValueKey.value],
              )?.label ||
            input.dropdownData.find(
              (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
            )[aliasValueKey.value]
        }

        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setTranformationOperationData(updateDrawerConfiguration)
    },
    [tranformationOperationData],
  )

  const onTextChangeForParams = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...paramsOfTransformationFunction]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (
          (input && input.key === 'output_dataframe') ||
          input.key === 'output_column'
        ) {
          const value = event.target.value
          const noSpacesValue = value.replace(/\s+/g, '')
          updateDrawerConfiguration[index].value = noSpacesValue
        } else {
          updateDrawerConfiguration[index].value = event.target.value
        }
      }
      setParamsOfTransformationFunction(updateDrawerConfiguration)
    },
    [paramsOfTransformationFunction],
  )

  const onTextAddedForMultipleInputForParams = useCallback(
    (arg: any) => {
      const { input, value } = arg
      const updateDrawerConfiguration = [...paramsOfTransformationFunction]

      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].value = value
      }
      setParamsOfTransformationFunction(updateDrawerConfiguration)
    },
    [paramsOfTransformationFunction],
  )

  const handleMultiObjDelete = useCallback(
    (arg: any) => {
      const { input, e: event, value } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].value = _.reject(
          updateDrawerConfiguration[index].value,
          value,
        )
      }
      setTranformationOperationData(updateDrawerConfiguration)
      event.preventDefault()
    },
    [tranformationOperationData],
  )

  const handleDelete = (arg: any) => {
    const { input, e: event, value } = arg
    const updateDrawerConfiguration = [...tranformationOperationData]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      updateDrawerConfiguration[index].value = _without(
        updateDrawerConfiguration[index].value,
        value,
      )
    }
    setTranformationOperationData(updateDrawerConfiguration)
    event.preventDefault()
  }

  const handleDialogClose = useCallback(() => {
    setNodeName('')
    setTranformationOperationData([])
    handleClose()
  }, [handleClose])

  const onUpdateComponentNodeApi = useCallback(
    async (args: any) => {
      const { payload, isEdit = false, newOperation } = args
      try {
        setLoading(true)
        await ApiServices.updateComponentNode(payload)
        setLoading(false)
        updateTransformationApply(newOperation, isEdit)
        handleDialogClose()
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [handleDialogClose, setLoading, updateTransformationApply],
  )

  const onClickUpdateComponent = useCallback(() => {
    let inputData = [...tranformationOperationData]

    let column_name = ''
    let data_type = ''

    const transformFunctionsItem = inputData.find(
      (item) => item.key === NODE_TYPES.transformation.keys.transform_functions,
    )

    if (transformFunctionsItem) {
      const transformFunctionsValue = transformFunctionsItem.value
      const lastValueId =
        transformFunctionsValue[transformFunctionsValue.length - 1]

      data_type = transformFunctionsItem.dropdownData.find(
        (item: any) => item.id === lastValueId,
      )?.return_type

      if (
        transformFunctionsItem.type === CONFIGURATION_INPUT_TYPES.SINGLETEXT
      ) {
        transformFunctionsItem.params = paramsOfTransformationFunction
      }
    }

    const outputColumnItem = inputData.find(
      (item) => item.key === NODE_TYPES.transformation.keys.output_column,
    )

    if (outputColumnItem) {
      column_name = outputColumnItem.value
    }

    inputData = inputData.map((item: ConfigurationInputInterface) => {
      if (item.dropdownData) delete item.dropdownData
      return item
    })

    const newNodeId = openTransformerPopup.isEdit
      ? openTransformerPopup.id
      : getRandomNumber()

    const payload = {
      id: newNodeId,
      project_id: projectId,
      component_node_name: nodeName,
      component_id: componentId,
      component_node_type_id: componentNodeTypeId,
      configuration: {
        input: inputData,
      },
      status: 'live',
    }

    const newOperation = {
      id: newNodeId,
      component_node_name: nodeName,
      column_name,
      data_type,
    }

    onUpdateComponentNodeApi({
      payload,
      isEdit: openTransformerPopup.isEdit,
      newOperation,
    })
  }, [
    tranformationOperationData,
    nodeName,
    componentId,
    onUpdateComponentNodeApi,
    openTransformerPopup,
    NODE_TYPES,
    paramsOfTransformationFunction,
  ])

  const onClickAddForParams = useCallback(
    (args: any) => {
      const { input } = args
      const inputValue = input.value
      let newParamPair = [...inputValue[0]]
      newParamPair = newParamPair.map((item: any, index: number) => {
        return {
          ...item,
          index: input.value.length,
          nestedIndex: index,
          parentKey: input.key,
          value: '',
        }
      })
      const updateDrawerConfiguration = [...paramsOfTransformationFunction]

      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )

      if (index > -1) {
        const oldInputValue = updateDrawerConfiguration[index].value
        const newInputValue = [...oldInputValue, [...newParamPair]]
        updateDrawerConfiguration[index].value = newInputValue
      }
      setParamsOfTransformationFunction(updateDrawerConfiguration)
    },
    [paramsOfTransformationFunction],
  )

  const onTextChangeMultirow = useCallback(
    (args: any) => {
      const { input, e: event } = args
      const parentIndex = input.index
      const nestedIndex = input.nestedIndex
      const parentKey = input.parentKey
      const updateDrawerConfiguration = [...paramsOfTransformationFunction]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === parentKey,
      )
      if (index > -1) {
        const inputValue = updateDrawerConfiguration[index].value
        inputValue[parentIndex][nestedIndex].value = event.target.value
        updateDrawerConfiguration[index].value = inputValue
      }
      setParamsOfTransformationFunction(updateDrawerConfiguration)
    },
    [paramsOfTransformationFunction],
  )

  const onTextAddedForMultipleInputMultirow = useCallback(
    (args: any) => {
      const { input, value } = args
      const parentIndex = input.index
      const nestedIndex = input.nestedIndex
      const parentKey = input.parentKey

      const updateDrawerConfiguration = [...paramsOfTransformationFunction]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === parentKey,
      )
      if (index > -1) {
        const inputValue = updateDrawerConfiguration[index].value
        inputValue[parentIndex][nestedIndex].value = value
        updateDrawerConfiguration[index].value = inputValue
      }
      setParamsOfTransformationFunction(updateDrawerConfiguration)
    },
    [paramsOfTransformationFunction],
  )

  return (
    <React.Fragment>
      <Dialog
        open={openTransformerPopup.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.stopPropagation()
            event.preventDefault()
            onClickUpdateComponent()
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">{dialogTitle} Name</Typography>
            <TextField
              required
              fullWidth
              variant="outlined"
              value={nodeName}
              onChange={(e) => {
                setNodeName(e.target.value)
              }}
            />
          </Box>
          {tranformationOperationData.map(
            (item: ConfigurationInputInterface) => {
              return DynamicFormForNodeDetails(
                item,
                {
                  onTextChange,
                  onDropDownChange,
                  handleMultiObjDelete,
                  handleDelete,
                  onMultiColumnTextChange: onMultiColumnTextChange,
                  onMultiColumnCheckboxChange: onMultiColumnCheckboxChange,
                  setDrawerConfiguration: setTranformationOperationData,
                  tranformationOperationData: tranformationOperationData,
                },
                DrawerWidth,
                classes,
                {
                  singleTextSearchInputRef,
                  singleTextSearch,
                  setSingleTextSearch,
                },
                {
                  multiTextSearchInputRef,
                  multiTextSearch,
                  setMultiTextSearch,
                },
                {
                  MultiObjTextSearchInputRef,
                  MultiObjTextSearch,
                  setMultiObjSearch,
                },
                showNodePropertiesDrawer,
                projectRole,
                drawerConfiguration,
                onEditMultiNode,
                onDeleteMultiNode,
                handleTransformationPopup,
                isInputNodeError,
                handleSingleFolderClick,
              )
            },
          )}
          {paramsOfTransformationFunction.map(
            (item: ConfigurationInputInterface) => {
              return DynamicFormForNodeDetails(
                item,
                {
                  onDropDownChange: onDropDownChangeForParams,
                  onTextChange: onTextChangeForParams,
                  onTextAddedForMultipleInput:
                    onTextAddedForMultipleInputForParams,
                  onClickAdd: onClickAddForParams,
                  multiRowChangeMethods: {
                    onTextChange: onTextChangeMultirow,
                    onTextAddedForMultipleInput:
                      onTextAddedForMultipleInputMultirow,
                  },
                },
                DrawerWidth,
                classes,
                {
                  singleTextSearchInputRef,
                  singleTextSearch,
                  setSingleTextSearch,
                },
                {
                  multiTextSearchInputRef,
                  multiTextSearch,
                  setMultiTextSearch,
                },
                {
                  MultiObjTextSearchInputRef,
                  MultiObjTextSearch,
                  setMultiObjSearch,
                },
                showNodePropertiesDrawer,
                projectRole,
                drawerConfiguration,
                onEditMultiNode,
                onDeleteMultiNode,
                handleTransformationPopup,
                isInputNodeError,
                handleSingleFolderClick,
              )
            },
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button disabled={!projectRole?.edit_nodes} type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default DocProcessingDataDialog
