/* eslint-disable no-unused-vars */
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import DynamicForm from 'components/molecules/DynamicForm'
import React, { useCallback, useEffect, useState } from 'react'
import { AddDataSourceProps } from 'types/props-types'

const GraphFilter = ({
  heading,
  setFilterData,
  messageConfig,
  showMessage,
  filterData,
}: any) => {
  const [editConfiguration, setEditConfiguration] = useState<any>(filterData)

  const [error, setError] = useState<any>({})

  const [isSaveLoading, setSaveLoading] = useState(false)

  useEffect(() => {
    setEditConfiguration(filterData)
  }, [filterData])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    let isValidationOk = true
    if (editConfiguration.length === 0) {
      return
    }
    editConfiguration?.some((item: any) => {
      if (item?.value === '') {
        if (item?.isRequired === true) {
          setError({
            ...error,
            [item?.key]: 'This is Mandatory Field',
          })
          isValidationOk = false
          return true
        }
      }
    })

    return isValidationOk
  }, [editConfiguration])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setFilterData(editConfiguration)
  }, [editConfiguration])

  const handleResetData = useCallback(() => {
    const resetData = editConfiguration.map((item: any) => {
      return {
        ...item,
        value: '',
      }
    })

    setFilterData(resetData)
  }, [editConfiguration])

  console.log('editConfiguration', editConfiguration)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {editConfiguration?.length > 0 && (
          <Box>
            <DynamicForm
              formData={editConfiguration}
              handleChange={setEditConfiguration}
              error={error}
              setError={setError}
              type="add"
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          <Button
            sx={{ color: '#42526E' }}
            variant="text"
            onClick={handleResetData}
            disabled={isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Reset Filter'}
          </Button>
          <Button
            sx={{ color: '#42526E' }}
            variant="text"
            onClick={handleSaveData}
            disabled={isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Apply Filter'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default GraphFilter
