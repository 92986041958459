/* eslint-disable no-unused-vars */
import { ApiServices } from 'api/services'
import React, { useCallback, useEffect, useState } from 'react'
import ChartTable from './ChartTable'

// import DataSourceTable from 'components/DataSourceTable'

interface TableChartComponentProps {
  chartData: any
  setLoading?: any
  index?: any
  filterData?: any
}

function TableChartComponent(props: TableChartComponentProps) {
  const { chartData, setLoading, index = 0, filterData } = props

  const [tableDetails, setTableDetails] = useState<any>()
  const [tableColumns, setTableColumns] = useState<any>([])
  const [tableRowData, setTableRowData] = useState<any>()

  // console.log('Table Row Data= ', tableRowData)

  useEffect(() => {
    if (chartData) {
      if (chartData.configuration.snapshot[index].configuration.snapshot) {
        const snapshot =
          chartData.configuration.snapshot[index].configuration.snapshot
        const configuration: any = {
          component_id: snapshot?.data_acess_api,
          component_node_id: snapshot?.endpoint_node,
          filter_columns: [],
          filter_vectors: [],
        }

        setTableDetails(configuration)
      }
    }
    if (chartData) {
      const columns: any = []
      const retain_columns =
        chartData.configuration.snapshot[index].configuration.snapshot
          .retain_columns

      const dataColumns = [...retain_columns]

      dataColumns
        .filter((item: any) => item.selected === true)
        .map((col: any) => {
          if (col !== null) {
            const colData = {
              field: col.column_name,
              headerName: col.alias || col.label,
              flex: 1,
              // width: 150,
            }
            columns.push(colData)
          }
        })
      setTableColumns(columns)
    }
  }, [chartData])

  useEffect(() => {
    if (filterData && filterData.length > 0) {
      const isFilterDataValid = filterData?.some(
        (item: any) => item.value !== '',
      )
      if (isFilterDataValid) {
        if (chartData.configuration.snapshot[index].configuration.snapshot) {
          const snapshot =
            chartData.configuration.snapshot[index].configuration.snapshot
          const isVectorFilter = filterData.some(
            (item: any) => item.dataType === 'dense_vector',
          )
          const configuration: any = {
            component_id: snapshot?.data_acess_api,
            component_node_id: snapshot?.endpoint_node,
            filter_columns: filterData
              .filter(
                (item: any) =>
                  item.value !== '' && item.dataType !== 'dense_vector',
              )
              .map((item: any) => {
                return {
                  column_name: item.key,
                  data_type: item.dataType,
                  value: item.value,
                  filter_condition: item.condition,
                }
              }),
          }
          if (isVectorFilter) {
            configuration.filter_vectors = filterData
              .filter(
                (item: any) =>
                  item.value !== '' && item.dataType === 'dense_vector',
              )
              .map((item: any) => {
                return {
                  column_name: item.key,
                  data_type: item.dataType,
                  value: item.value,
                  filter_condition: item.condition,
                }
              })
          }

          setTableDetails(configuration)
        }
      } else {
        if (chartData.configuration.snapshot[index].configuration.snapshot) {
          const snapshot =
            chartData.configuration.snapshot[index].configuration.snapshot
          const configuration: any = {
            component_id: snapshot?.data_acess_api,
            component_node_id: snapshot?.endpoint_node,
            filter_columns: [],
            filter_vectors: [],
          }

          setTableDetails(configuration)
        }
      }
      if (chartData) {
        const columns: any = []
        const retain_columns =
          chartData.configuration.snapshot[index].configuration.snapshot
            .retain_columns

        const dataColumns = [...retain_columns]

        dataColumns
          .filter((item: any) => item.selected === true)
          .map((col: any) => {
            if (col !== null) {
              const colData = {
                field: col.column_name,
                headerName: col.alias || col.label,
                flex: 1,
                // width: 150,
              }
              columns.push(colData)
            }
          })
        setTableColumns(columns)
      }
    }
  }, [filterData])

  const callGetAllColumnsApi = useCallback(
    async (tableDetails: any) => {
      try {
        setLoading?.(true)
        const resp = await ApiServices.getDetailsColumnApiData({
          component_id: tableDetails.component_id,
          component_node_id: tableDetails.component_node_id,
          filter_columns: tableDetails.filter_columns,
          filter_vectors: tableDetails.filter_vectors,
          record_limit: 10,
        })
        setLoading(false)
        setTableRowData(
          JSON.parse(resp.data.dataset_records).map(
            (item: any, index: number) => ({ ...item, id: index }),
          ),
        )
      } catch (error) {
        setLoading?.(false)
        console.log('error', error)
      }
    },
    [tableDetails],
  )

  useEffect(() => {
    if (tableDetails) {
      callGetAllColumnsApi(tableDetails)
    }
  }, [tableDetails])

  return (
    <div>
      {tableColumns && (
        <ChartTable columns={tableColumns} rows={tableRowData} />
      )}
    </div>
  )
}

export default TableChartComponent
