/* eslint-disable prettier/prettier */
import React, { useCallback, useMemo } from 'react'
import lodash from 'lodash'
import { Box, Stack } from '@mui/material'
import Text from 'components/atoms/Text'
import { DynamicFormProps } from 'types/props-types'
import SingleTextDropdown from 'components/atoms/SingleTextDropdown'

function DynamicForm({
  formData,
  handleChange,
  type,
  error,
  setError,
}: DynamicFormProps) {
  // const { formData, handleChange } = prop
  const state = useMemo(() => lodash.cloneDeep(formData), [formData])

  const handleTextChange = useCallback(
    (event: any) => {
      if (type !== 'view') {
        const index = state?.findIndex(
          (item: any) => item?.key === event?.target?.name,
        )
        if (type === 'edit' && state[index].isEditable === true) {
          state[index].value = event?.target?.value
          handleChange(state)
          setError?.({ ...error, [state[index].key]: '' })
        } else if (type === 'add') {
          state[index].value = event?.target?.value
          handleChange(() => [...state])
          setError?.({ ...error, [state[index].key]: '' })
        } else if (type === 'clone') {
          state[index].value = event?.target?.value
          handleChange(state)
          setError?.({ ...error, [state[index].key]: '' })
        }
      }
    },
    [formData, type],
  )

  // const handleDropDownChange = (event: any, name: any) => {
  //   if (type !== 'view') {
  //     const index = state?.findIndex((item: any) => item?.key === name)
  //     state[index].value = event
  //     handleChange(state)
  //     setError?.({ ...error, [state[index].key]: '' })
  //   }
  // }

  const handleDropDownChange = (event: any, name: string) => {
    if (type !== 'view') {
      handleChange((prevState: any) => {
        const updatedState = prevState.map((item: any) =>
          item.key === name ? { ...item, value: event } : item,
        )
        setError((prevErrors: any) => ({ ...prevErrors, [name]: '' }))
        return updatedState
      })
    }
  }

  return (
    <Stack gap={'32px'}>
      {formData &&
        formData
          ?.filter((item: any) => item.allowDisplay !== false)
          .map((item: any, index: number) => {
            if (item?.type === 'TEXTINPUT') {
              return (
                <Text
                  key={index}
                  label={item?.isRequired ? item?.label + ' *' : item?.label}
                  value={item?.value}
                  name={item?.key}
                  onChange={handleTextChange}
                  error={error}
                  disabled={type === 'view'}
                />
              )
            } else if (item?.type === 'TEXTAREA') {
              return (
                <Text
                  key={index}
                  label={item?.isRequired ? item?.label + ' *' : item?.label}
                  value={item?.value}
                  name={item?.key}
                  onChange={handleTextChange}
                  error={error}
                  multiline={true}
                  disabled={type === 'view'}
                />
              )
            } else if (item?.type === 'MULTITEXT') {
              return (
                <Box key={item}>
                  <SingleTextDropdown
                    label={item?.label}
                    isRequired={item?.isRequired}
                    name={item?.key}
                    options={item?.options}
                    value={item?.value}
                    onChange={handleDropDownChange}
                    disabled={type === 'view'}
                  />
                </Box>
              )
            } else if (item?.type === 'SINGLETEXT') {
              return (
                <Box key={item}>
                  <SingleTextDropdown
                    label={item?.label}
                    isRequired={item?.isRequired}
                    name={item?.key}
                    options={item?.options}
                    value={item?.value}
                    onChange={handleDropDownChange}
                    disabled={type === 'view'}
                  />
                </Box>
              )
            }
          })}
    </Stack>
  )
}

export default DynamicForm
